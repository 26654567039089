/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "~jquery.mmenu/dist/jquery.mmenu.all.css";

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: rgba(0,0,0,0);
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: .3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0,0,0,.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0,0,0,.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0,0,0,.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0,0,0,.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255,255,255,.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255,255,255,.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: rgba(0,0,0,0);
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: rgba(0,0,0,0);
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(0,0,0,0);
  border: solid rgba(0,0,0,0);
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: .25rem .5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: .25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40,167,69,.9);
  border-radius: .25rem;
}

.form-row>.col>.valid-tooltip,
.form-row>[class*=col-]>.valid-tooltip {
  left: 5px;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.25);
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220,53,69,.9);
  border-radius: .25rem;
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*=col-]>.invalid-tooltip {
  left: 5px;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.25);
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat,#fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem rgba(38,143,255,.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38,143,255,.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem rgba(130,138,145,.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130,138,145,.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem rgba(72,180,97,.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(72,180,97,.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem rgba(58,176,195,.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(58,176,195,.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem rgba(222,170,12,.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(222,170,12,.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem rgba(225,83,97,.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(225,83,97,.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem rgba(216,217,219,.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(216,217,219,.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem rgba(82,88,93,.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(82,88,93,.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: rgba(0,0,0,0);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: rgba(0,0,0,0);
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: rgba(0,0,0,0);
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: rgba(0,0,0,0);
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: rgba(0,0,0,0);
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: rgba(0,0,0,0);
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: rgba(0,0,0,0);
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: rgba(0,0,0,0);
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: .5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width .35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid rgba(0,0,0,0);
  border-bottom: 0;
  border-left: .3em solid rgba(0,0,0,0);
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid rgba(0,0,0,0);
  border-bottom: .3em solid;
  border-left: .3em solid rgba(0,0,0,0);
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0,0,0,0);
  border-right: 0;
  border-bottom: .3em solid rgba(0,0,0,0);
  border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0,0,0,0);
  border-right: .3em solid;
  border-bottom: .3em solid rgba(0,0,0,0);
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0,0,0,0);
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: rgba(0,0,0,0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: flex;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation)>.form-control:not(:last-child),
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation>.form-control:nth-last-child(n+3),
.input-group.has-validation>.custom-select:nth-last-child(n+3),
.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: .25rem .5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0,123,255,.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0,123,255,.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0,123,255,.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0,123,255,.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: rgba(0,0,0,0);
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem .75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: rgba(0,0,0,0);
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: rgba(0,0,0,0);
  cursor: pointer;
  background-color: #dee2e6;
  border-color: rgba(0,0,0,0);
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: rgba(0,0,0,0);
  cursor: pointer;
  background-color: #dee2e6;
  border-color: rgba(0,0,0,0);
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: rgba(0,0,0,0);
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
  border-width: .5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
  border-radius: .25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0,0,0,.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0,0,0,.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0,0,0,.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0,0,0,.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0,0,0,.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0,0,0,.5);
}

.navbar-light .navbar-text a {
  color: rgba(0,0,0,.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0,0,0,.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255,255,255,.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255,255,255,.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255,255,255,.5);
  border-color: rgba(255,255,255,.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255,255,255,.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0,0,0,.03);
  border-top: 1px solid rgba(0,0,0,.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(108,117,125,.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(40,167,69,.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(23,162,184,.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255,193,7,.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(220,53,69,.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(248,249,250,.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(52,58,64,.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(0,0,0,0);
  border-radius: .25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: .75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255,255,255,.85);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 .25rem .75rem rgba(0,0,0,.1);
  opacity: 0;
  border-radius: .25rem;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255,255,255,.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0,0,0,.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: rgba(0,0,0,0);
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: rgba(0,0,0,0);
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^=top] {
  margin-bottom: .5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^=top]>.arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^=top]>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0,0,0,.25);
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^=top]>.arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^=right] {
  margin-left: .5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^=right]>.arrow {
  left: calc(-0.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^=right]>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0,0,0,.25);
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^=right]>.arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^=bottom] {
  margin-top: .5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^=bottom]>.arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^=bottom]>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0,0,0,.25);
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^=bottom]>.arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^=left] {
  margin-right: .5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^=left]>.arrow {
  right: calc(-0.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^=left]>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0,0,0,.25);
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^=left]>.arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: .5rem .75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid rgba(0,0,0,0);
  border-bottom: 10px solid rgba(0,0,0,0);
  opacity: .5;
  transition: opacity .6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: .25em solid currentcolor;
  border-right-color: rgba(0,0,0,0);
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: rgba(0,0,0,0) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-lg {
  border-radius: .3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1,
.my-1 {
  margin-top: .25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2,
.my-2 {
  margin-top: .5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1,
.py-1 {
  padding-top: .25rem !important;
}

.pr-1,
.px-1 {
  padding-right: .25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important;
}

.pl-1,
.px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2,
.py-2 {
  padding-top: .5rem !important;
}

.pr-2,
.px-2 {
  padding-right: .5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important;
}

.pl-2,
.px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0);
}

.text-monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0,0,0,.5) !important;
}

.text-white-50 {
  color: rgba(255,255,255,.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: rgba(0,0,0,0);
  text-shadow: none;
  background-color: rgba(0,0,0,0);
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody+tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.qs-datepicker-container {
  font-size: 1rem;
  font-family: sans-serif;
  color: #000;
  position: absolute;
  width: 15.625em;
  display: flex;
  flex-direction: column;
  z-index: 9001;
  user-select: none;
  border: 1px solid gray;
  border-radius: .263921875em;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1.25em 1.25em -0.9375em rgba(0,0,0,.3);
}

.qs-datepicker-container * {
  box-sizing: border-box;
}

.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qs-hidden {
  display: none;
}

.qs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.75);
  color: #fff;
  width: 100%;
  height: 100%;
  padding: .5em;
  z-index: 1;
  opacity: 1;
  transition: opacity .3s;
  display: flex;
  flex-direction: column;
}

.qs-overlay.qs-hidden {
  opacity: 0;
  z-index: -1;
}

.qs-overlay .qs-overlay-year {
  border: none;
  background: rgba(0,0,0,0);
  border-bottom: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: .875em;
  padding: .25em 0;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.qs-overlay .qs-close {
  padding: .5em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.qs-overlay .qs-submit {
  border: 1px solid #fff;
  border-radius: .263921875em;
  padding: .5em;
  margin: 0 auto auto;
  cursor: pointer;
  background: rgba(128,128,128,.4);
}

.qs-overlay .qs-submit.qs-disabled {
  color: gray;
  border-color: gray;
  cursor: not-allowed;
}

.qs-overlay .qs-overlay-month-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.qs-overlay .qs-overlay-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  cursor: pointer;
  opacity: .5;
  transition: opacity .15s;
}

.qs-overlay .qs-overlay-month.active,
.qs-overlay .qs-overlay-month:hover {
  opacity: 1;
}

.qs-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  background: #d3d3d3;
  filter: blur(0px);
  transition: filter .3s;
}

.qs-controls.qs-blur {
  filter: blur(5px);
}

.qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  position: relative;
  cursor: pointer;
  border-radius: .263921875em;
  transition: background .15s;
}

.qs-arrow:hover {
  background: rgba(0,0,0,.1);
}

.qs-arrow:hover.qs-left:after {
  border-right-color: #000;
}

.qs-arrow:hover.qs-right:after {
  border-left-color: #000;
}

.qs-arrow:after {
  content: "";
  border: .390625em solid rgba(0,0,0,0);
  position: absolute;
  top: 50%;
  transition: border .2s;
}

.qs-arrow.qs-left:after {
  border-right-color: gray;
  right: 50%;
  transform: translate(25%, -50%);
}

.qs-arrow.qs-right:after {
  border-left-color: gray;
  left: 50%;
  transform: translate(-25%, -50%);
}

.qs-month-year {
  font-weight: bold;
  transition: border .2s;
  border-bottom: 1px solid rgba(0,0,0,0);
}

.qs-month-year:not(.qs-disabled-year-overlay) {
  cursor: pointer;
}

.qs-month-year:not(.qs-disabled-year-overlay):hover {
  border-bottom: 1px solid gray;
}

.qs-month-year:focus,
.qs-month-year:active:focus {
  outline: none;
}

.qs-month {
  padding-right: .5ex;
}

.qs-year {
  padding-left: .5ex;
}

.qs-squares {
  display: flex;
  flex-wrap: wrap;
  padding: .3125em;
  filter: blur(0px);
  transition: filter .3s;
}

.qs-squares.qs-blur {
  filter: blur(5px);
}

.qs-square {
  width: calc(100% / 7);
  height: 1.5625em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background .1s;
  border-radius: .263921875em;
}

.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: orange;
}

.qs-current {
  font-weight: bold;
  text-decoration: underline;
}

.qs-active,
.qs-range-start,
.qs-range-end {
  background: #add8e6;
}

.qs-range-start:not(.qs-range-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-range-middle {
  background: #d4ebf2;
}

.qs-range-middle:not(.qs-range-0):not(.qs-range-6) {
  border-radius: 0;
}

.qs-range-middle.qs-range-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.qs-range-middle.qs-range-6 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-range-end:not(.qs-range-0) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.qs-disabled,
.qs-outside-current-month {
  opacity: .2;
}

.qs-disabled {
  cursor: not-allowed;
}

.qs-empty {
  cursor: default;
}

.qs-day {
  cursor: default;
  font-weight: bold;
  color: gray;
}

.qs-event {
  position: relative;
}

.qs-event:after {
  content: "";
  position: absolute;
  width: .46875em;
  height: .46875em;
  border-radius: 50%;
  background: #07f;
  bottom: 0;
  right: 0;
}

.splitter {
  display: block;
  margin: 5px 0;
}

.info-box {
  padding: 15px;
  border: 1px solid #050505;
}

.info-box.max-height {
  height: 100%;
}

.info-box.with-button {
  padding-bottom: 70px;
  position: relative;
}

.info-box .info-box--header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-box .info-box--header h3 {
  font-size: 30px;
  line-height: 34px;
  margin: 0;
  text-transform: capitalize;
}

.info-box .info-box--header .icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.info-box .info-box--header .icon.icon-big {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.info-box .info-box--header .info-headline {
  font-size: 21px;
  font-weight: 700;
  display: inline-block;
}

@media (max-width: 767px) {
  .info-box .info-box--header .info-headline {
    font-size: 18px;
  }
}

.info-box .info-box--body p {
  margin-bottom: 0;
  line-height: 20px;
}

@media (max-width: 767px) {
  .info-box .info-box--body p {
    font-size: 13px;
  }
}

.info-box .button-row {
  position: absolute;
  bottom: 15px;
  margin: 0 -15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

body {
  line-height: 1.45;
  font-size: 16px;
  font-family: "Source Sans Pro",sans-serif;
  font-weight: 400;
  color: #3b3b3b;
  background-color: #f0eff0;
}

.body {
  padding: 30px 0 50px 0;
}

@media (max-width: 767px) {
  .body {
    padding-top: 10px;
  }
}

svg.white {
  fill: #fff;
}

svg.black {
  fill: #000;
}

svg.anthracite {
  fill: #3b3b3b;
}

svg.primary {
  fill: #802538;
}

svg.secondary {
  fill: #050505;
}

svg.calculator.white {
  stroke: #fff;
}

svg.calculator.black {
  stroke: #000;
}

svg.calculator.anthracite {
  stroke: #3b3b3b;
}

svg.calculator.primary {
  stroke: #802538;
}

svg.calculator.secondary {
  stroke: #050505;
}

input:not(.portal--searchbar) {
  outline: 0 none !important;
}

input[type=radio] {
  -webkit-appearance: none;
  border: 1px solid #581a27;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 5px;
  background: #fff;
}

@media all and (-ms-high-contrast: none) {
  input[type=radio] *::-ms-backdrop,
  input[type=radio] {
    -webkit-appearance: none;
    border: 1px solid #581a27;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
  }

  input[type=radio]:checked {
    background: #802538;
  }
}

input[type=radio]:after {
  content: "";
  width: 5px;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  left: 50%;
  margin-left: -2.5px;
  margin-top: -2.5px;
}

input[type=radio]:checked {
  background: #802538;
}

.btn {
  font-size: 20px;
  font-weight: 700;
}

.btn-primary {
  vertical-align: top;
  border-radius: 0;
  width: 12em;
  background: #802538;
  border: 1px solid #802538;
}

.btn-primary--bordered {
  vertical-align: top;
  border-radius: 0;
  width: 12em;
  background: #fff;
  border: 2px solid #802538;
}

select.form-control,
input.form-control,
.form-control.input {
  height: auto;
  -webkit-appearance: none;
  border: 1px solid #f0eff0;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 0;
  box-shadow: none;
}

select.form-control.disabled,
input.form-control.disabled,
.form-control.input.disabled {
  background: rgba(245,245,245,.9843137255);
  cursor: not-allowed;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

select.form-control.disabled:hover,
select.form-control.disabled:focus,
select.form-control.disabled:active,
input.form-control.disabled:hover,
input.form-control.disabled:focus,
input.form-control.disabled:active,
.form-control.input.disabled:hover,
.form-control.input.disabled:focus,
.form-control.input.disabled:active {
  border-color: #f0eff0;
}

select.form-control:hover,
select.form-control:focus,
select.form-control:active,
input.form-control:hover,
input.form-control:focus,
input.form-control:active,
.form-control.input:hover,
.form-control.input:focus,
.form-control.input:active {
  border-color: #8d8d8d;
  box-shadow: none;
}

.select-value {
  position: relative;
}

.select-value:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 0;
}

.select-value input {
  z-index: 1;
  position: relative;
}

.invalid-feedback,
.alert-success,
.aimeos .error-list {
  padding: 15px;
  margin-bottom: 15px;
  display: block;
  font-weight: 200;
  font-size: 1rem;
  border: none;
  border-radius: 0;
  color: #fff;
  list-style: none;
}

.invalid-feedback,
.aimeos .error-list {
  background-color: #f44336;
}

.aimeos .error-item {
  margin-left: 20px;
  padding: 0;
}

.alert-success {
  background-color: #4bb543;
}

.alert-success .success-item {
  margin-left: 20px;
}

.container-short {
  width: 90%;
  min-height: 18em;
  margin: 0 auto;
}

.account-subscription .header,
.account-history .header,
.account-watch .header,
.account-favorite .header,
.basket-related-bought .header,
.catalog-session .header,
.catalog-detail .header {
  border-bottom: 1px solid #f0eff0;
  font-size: 1rem;
}

.aimeos-container {
  max-width: 75em;
}

#header-and-body {
  background-color: #fff;
}

.kontakt_text {
  font-weight: 700;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear {
  display: none;
}

.form-item.error label,
.service-form-item.error label {
  color: #f30 !important;
}

.form-item.error input,
.form-item.error textarea,
.form-item.error select,
.service-form-item.error input,
.service-form-item.error textarea,
.service-form-item.error select {
  background-color: #fee !important;
  border: solid 1px #f30 !important;
}

.error-text {
  color: #f30 !important;
}

.error-bg {
  background-color: #fee !important;
}

.error-border {
  border: solid 1px #f30 !important;
}

img:hover,
img:focus,
img:active {
  outline: 0 none !important;
}

.form-headline {
  margin-bottom: 35px;
}

.icon {
  min-width: 20px;
  min-height: 20px;
}

.arrow-right-white--icon,
.a-right:after {
  background-image: url("../images/icons/arrow-right-white.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-right-black--icon,
.a-right:after {
  background-image: url("../images/icons/arrow-right-black.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-left-white--icon,
.a-left:after {
  background-image: url("../images/icons/arrow-left-white.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-left-black--icon,
.a-left:after {
  background-image: url("../images/icons/arrow-left-black.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-down--icon,
.select-value:after {
  background-image: url("../images/icons/arrow-down.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-down--icon-white {
  background-image: url("../images/icons/arrow-down-white.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-down--icon-orange {
  background-image: url("../images/icons/arrow-down-orange.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-up--icon {
  background-image: url("../images/icons/arrow-up.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-up--icon-white {
  background-image: url("../images/icons/arrow-up-white.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-up--icon-orange {
  background-image: url("../images/icons/arrow-up-orange.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.plus--icon {
  background-image: url("../images/icons/plus.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.plus-blue--icon {
  background-image: url("../images/icons/plus-blue.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.minus--icon {
  background-image: url("../images/icons/minus.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.minus-blue--icon {
  background-image: url("../images/icons/minus-blue.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.edit--icon {
  background-image: url("../images/icons/edit.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.housebreaking--icon-secondary {
  background-image: url("../images/icons/housebreaking-secondary.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.damage--icon-secondary {
  background-image: url("../images/icons/damage-secondary.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.delete--icon,
.basket .table .table-body .product .delete {
  background-image: url("../images/icons/delete.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.haken--icon,
.all-around-protection .benefit:before {
  background-image: url("../images/icons/haken.svg");
  min-width: 20px;
  min-height: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.anhang {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.present {
  min-width: 28px;
  min-height: 28px;
}

.w-full {
  width: 100%;
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/source-sans-pro/SourceSansPro-Bold.ttf"),url("../fonts/source-sans-pro/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf"),url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/source-sans-pro/SourceSansPro-Light.ttf"),url("../fonts/source-sans-pro/SourceSansPro-Light.ttf");
}

h1,
h2,
h3 {
  font-family: "Source Sans Pro",sans-serif;
}

h1,
.aimeos h1 {
  font-weight: 200;
  line-height: 38px;
  font-size: 35px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  h1,
  .aimeos h1 {
    font-size: 30px;
    line-height: 35px;
  }
}

h2,
.aimeos h2 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 200;
  text-transform: none;
}

@media (max-width: 767px) {
  h2,
  .aimeos h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

h3,
.aimeos h3 {
  font-size: 23px;
  line-height: 23px;
  text-transform: uppercase;
  font-weight: 400;
}

a,
a:hover,
a:link,
a:visited,
a:active,
a:focus {
  text-decoration: none !important;
  outline: 0 none !important;
}

a,
.aimeos a,
.aimeos a:link:not(.btn),
.aimeos a:visited:not(.btn) {
  color: #3b3b3b;
  text-decoration: none;
}

p {
  text-align: initial !important;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

button,
.btn,
.btn-primary,
.btn-secondary,
.btn-primary--light,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  outline: 0 none !important;
  box-shadow: none;
  cursor: pointer;
}

button:hover,
button:focus,
button:active,
.btn:hover,
.btn:focus,
.btn:active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-primary--light:hover,
.btn-primary--light:focus,
.btn-primary--light:active,
.btn-primary:not(:disabled):not(.disabled):active:focus:hover,
.btn-primary:not(:disabled):not(.disabled):active:focus:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus:active,
.btn-primary:not(:disabled):not(.disabled).active:focus:hover,
.btn-primary:not(:disabled):not(.disabled).active:focus:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus:active,
.show>.btn-primary.dropdown-toggle:focus:hover,
.show>.btn-primary.dropdown-toggle:focus:focus,
.show>.btn-primary.dropdown-toggle:focus:active {
  outline: 0 none !important;
  box-shadow: none;
}

.aimeos .btn-primary {
  background-color: #802538;
  border: 1px solid #802538;
}

.aimeos .btn-primary:hover,
.aimeos .btn-primary:focus,
.aimeos .btn-primary:active {
  background-color: #581a27;
  border: 1px solid #581a27;
}

.btn {
  background: #f0eff0;
  border: 1px solid #f0eff0;
  border-radius: 0;
  padding: 5px 25px;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: #802538;
  border: 1px solid #802538;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active:hover,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active:active,
.btn-primary:not(:disabled):not(.disabled).active:hover,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled).active:active,
.show>.btn-primary.dropdown-toggle:hover,
.show>.btn-primary.dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle:active {
  background-color: #581a27;
  border: 1px solid #581a27;
}

.btn-primary--light {
  background-color: #a83049;
  border: 1px solid #a83049;
}

.btn-primary--light:hover,
.btn-primary--light:focus,
.btn-primary--light:active {
  background-color: #942b41;
  border: 1px solid #942b41;
}

.btn-secondary {
  background-color: #050505;
  border: 1px solid #050505;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: #000;
  border: 1px solid #000;
}

.btn-primary--rounded {
  border-radius: 25px;
  padding: 5px 25px;
  width: auto;
  background: #802538;
  border: 1px solid #802538;
  color: #fff;
  font-weight: 200;
}

.btn-primary--rounded:hover,
.btn-primary--rounded:focus,
.btn-primary--rounded:active {
  color: #fff;
  background-color: #581a27;
  border: 1px solid #581a27;
}

.btn-gray {
  background-color: #bababa;
  border-color: #bababa;
  color: #fff;
  border: 0 none;
}

.btn-decline {
  background-color: rgba(0,0,0,0);
  color: #802538;
  border: 0 none;
}

.btn-back {
  border: 1px solid #802538;
  color: #802538;
  background-color: rgba(0,0,0,0);
  margin-right: 10px;
}

.normal-width {
  padding: 5px 90px;
  width: 15.8em !important;
}

.btn-next {
  padding: 5px 90px;
}

input[type=text]:not(.portal--searchbar),
input[type=email],
input[type=password],
input[type=time],
select,
textarea {
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 45px;
}

.input {
  min-height: 45px;
}

.aimeos input:disabled,
input:disabled {
  background: rgba(245,245,245,.9843137255);
  border: 1px solid #f0eff0;
  cursor: not-allowed;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.aimeos input:disabled:hover,
.aimeos input:disabled:focus,
.aimeos input:disabled:active,
input:disabled:hover,
input:disabled:focus,
input:disabled:active {
  border-color: #f0eff0;
}

input[type=checkbox] {
  opacity: 0;
  height: 0;
  width: 0;
}

input[type=checkbox]:checked~span.checkbox {
  background-color: #802538;
}

input[type=checkbox]:checked~span.checkbox:after {
  content: "✓";
  color: #fff;
  font-size: 11px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  text-align: center;
  transform: translateY(-50%);
  height: 12px;
}

span.checkbox {
  position: absolute;
  border: 1px solid #581a27;
  width: 15px;
  height: 15px;
  display: inline-block;
  right: 15px;
  top: 50%;
  margin-top: -7.5px;
}

.info {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.info .info-text {
  display: none;
  position: absolute;
  width: 265px;
  background: #fff;
  padding: 20px;
  height: auto;
  font-size: 12px;
  font-weight: 200;
  text-align: left;
  white-space: normal;
  z-index: 9999;
  box-shadow: 2px 2px 10px rgba(0,0,0,.2);
}

.info .info-text ul,
.info .info-text ol {
  margin: 0;
  padding-left: 20px;
}

.info .info-text ul li,
.info .info-text ol li {
  padding: 7px 0;
}

.info .info-text.right {
  right: -275px;
  left: auto;
}

@media (max-width: 767px) {
  .info .info-text.right {
    right: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.info .info-text.left {
  left: -275px;
}

@media (max-width: 767px) {
  .info .info-text.left {
    right: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.info .info-text.bottom {
  left: -155px;
}

@media (max-width: 767px) {
  .info .info-text.bottom {
    right: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .info .info-text {
    top: 50vh;
  }
}

.info .icon:hover~.info-text {
  display: block;
}

@media (max-width: 767px) {
  .info .icon:hover~.info-text {
    display: none;
  }
}

@media (max-width: 767px) {
  .info {
    position: unset !important;
  }
}

.all-around-protection-hint {
  margin: 10px 0;
  border: 1px solid #03d138;
  border-radius: 12px;
  padding: 10px 15px;
}

.all-around-protection-hint .protection-hint-text {
  color: #03d138;
  display: block;
  line-height: 20px;
  font-size: 15px;
}

.announcement {
  height: 75px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 30px 2px #ddd;
}

.announcement.note-open {
  display: block;
  animation: flash-show 300ms ease 0s;
}

.announcement.note-closed {
  animation: flash-hide 300ms ease 0s;
  display: none;
}

.announcement .note-box {
  margin: 0 50px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.announcement .note-box .text {
  display: inline-block;
  line-height: 75px;
  vertical-align: top;
  font-size: .9em;
  font-weight: 300;
  color: rgba(25,25,25,.75);
  padding-left: 25px;
}

@keyframes flash-hide {
  0% {
    right: 20px;
    opacity: 1;
  }

  100% {
    right: -100%;
    opacity: 0;
  }
}

@keyframes flash-show {
  0% {
    right: -100%;
    opacity: 0;
  }

  100% {
    right: 20px;
    opacity: 1;
  }
}

#cookieconsent {
  z-index: 999;
  position: fixed;
  width: 100%;
  background: rgba(0,0,0,.9);
  left: 0;
  right: 0;
  bottom: 0;
  padding: 35px 25px;
  color: #fff;
}

#cookieconsent p {
  margin: 0;
}

#cookieconsent a {
  color: #fff;
  text-decoration: none;
}

#cookieconsent a:hover,
#cookieconsent a:focus,
#cookieconsent a:active {
  text-decoration: none;
  color: #fff;
}

#cookieconsent.fadeOutOpacity {
  pointer-events: none;
  animation: fadeOutOpacity .3s ease forwards;
}

@keyframes fadeOutOpacity {
  to {
    opacity: 0;
  }
}

.calculator .isloading {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.pop-up #loader-dots {
  display: none;
}

.pop-up #loader-dots.isloading {
  display: flex;
  justify-content: center;
}

.pop-up #loader-dots.isloading span {
  background-color: #000 !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.c-primary {
  color: #802538 !important;
}

.c-white {
  color: #fff !important;
}

.c-black {
  color: #000 !important;
}

.c-dark-gray {
  color: #3b3b3b !important;
}

.no-p {
  padding: 0 !important;
}

.no-m {
  margin: 0 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-regular {
  font-weight: 400 !important;
}

.bc-p {
  border-color: #802538;
}

.b-transparent {
  background-color: rgba(0,0,0,0) !important;
}

.no-border {
  border: 0 none !important;
}

.b-primary {
  background-color: #802538;
}

.b-white {
  background-color: #fff !important;
}

.border-radius-15 {
  border-radius: 15px;
}

.t-no-wrap {
  white-space: nowrap;
}

.t-capitalized {
  text-transform: capitalize !important;
}

.t-uppercase {
  text-transform: uppercase !important;
}

.border-gray {
  border-color: #d5d5d5 !important;
}

.o-hidden {
  overflow: hidden !important;
}

.o-auto {
  overflow-y: auto;
}

.mr-30 {
  margin-right: 30px !important;
}

.breadcrumb-navi {
  margin-bottom: 60px;
  font-weight: 700;
  border-bottom: 1px dotted #d5d5d5;
  padding-bottom: 30px;
}

.breadcrumb-navi .arrow-left {
  margin-right: 10px;
}

.breadcrumb-navi .text-left {
  position: relative;
}

.breadcrumb-navi .text-left a {
  font-size: 17px;
}

.breadcrumb-navi .text-left p {
  display: inline-block;
}

.breadcrumb-navi .text-left .icon {
  width: 30px;
  height: 30px;
}

.catalog-detail-image .bu-image-single {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #fff;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 533px;
  width: 100%;
}

.catalog-detail-image .bu-image-single .media-item {
  background-color: #fff;
  background-image: url(../images/progress.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  height: 533px;
  width: 100%;
}

.catalog-detail-image .bu-image-single .media-item .item {
  transform: translate(-50%, -50%);
  position: relative;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
}

.article-price {
  display: inline-block;
}

.article-price .price-text {
  line-height: normal;
}

.article-price .price-text .value {
  font-weight: 400;
  vertical-align: inherit;
  font-family: Neuzeit Bold,sans-serif;
  font-size: 46px;
  margin: 0;
  display: inline;
  vertical-align: middle;
  color: #3b3b3b;
}

.catalog-detail-image .bu-image-single .media-item {
  display: none;
}

.catalog-detail-image .bu-image-single .media-item.selected {
  display: block;
}

.catalog-detail-image .thumbs .bu-item {
  display: inline-block;
  height: 105px;
  width: 80px;
  opacity: .7;
  margin: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.catalog-detail-image .thumbs .bu-item:hover {
  opacity: 1;
}

.aimeos.catalog-list .product {
  border-bottom: 0;
}

.aimeos.catalog-list .product .price-list {
  padding: 15px 0;
  background-color: #802538;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  height: 33px;
}

.aimeos.catalog-list .product .price-list:hover .price-text {
  margin-top: -26px;
}

.aimeos.catalog-list .product .price-list .price-actual {
  height: 26px;
  overflow: hidden;
}

.aimeos.catalog-list .product .price-list .price-actual .price-text {
  transition: all .3s ease;
  padding-top: 2px;
}

.aimeos.catalog-list .product .price-list .price-actual .price-text.isloading {
  display: none;
}

.aimeos.catalog-list .product .price-list .price-actual .calc-icon {
  display: none;
}

.aimeos.catalog-list .product .price-list .price-actual .ordernow__button-text.isloading,
.aimeos.catalog-list .product .price-list .price-actual .cost.isloading {
  display: none;
}

.aimeos.catalog-list .product .text-list {
  margin: 2em auto 1em auto;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #f0eff0;
  padding-top: 10px;
}

.aimeos.catalog-list .product .text-list h2 {
  font-size: 18px;
  text-transform: inherit;
  font-weight: 700;
  height: auto;
  color: #3b3b3b;
  line-height: 20px;
  margin-bottom: 0;
}

.aimeos.catalog-list .product .text-list .text-item {
  font-size: 18px;
  height: auto;
}

.aimeos.catalog-list .product .offer {
  margin-top: 15px;
}

.aimeos.catalog-list .catalog-detail .ordernow__button-text {
  display: none;
}

@keyframes opacitychange {
  0%, 100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

.last-seen.catalog-list .media-item {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.catalog-list a .media-list,
.aimeos .suggest-item .item-image,
.basket-related-bought .media-list,
.catalog-detail-bought .media-list,
.catalog-detail-bundle .media-list,
.catalog-detail-suggest .media-list,
.catalog-session-seen .media-list,
.account-watch .media-item,
.account-favorite .media-item,
.basket-related-bought .media-item,
.catalog-detail-bought .media-item,
.catalog-detail-bundle .media-item,
.catalog-detail-suggest .media-item,
.catalog-session-seen .media-item,
.catalog-session-pinned .media-item,
.catalog-detail-image .image-single,
.catalog-detail-image .bu-image-single {
  background-image: url("../images/placeholder.png");
}

#loader-dots.isloading {
  display: inline-block;
}

#loader-dots.isloading span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #fff;
  margin: 12px 5px;
  opacity: 0;
}

#loader-dots.isloading span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

#loader-dots.isloading span:nth-child(2) {
  animation: opacitychange 1s ease-in-out .33s infinite;
}

#loader-dots.isloading span:nth-child(3) {
  animation: opacitychange 1s ease-in-out .66s infinite;
}

.show-details.isloading {
  display: none !important;
}

.delivery-status {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 15px;
}

.delivery-status.status-unfinished {
  background-color: #6da0be;
}

.delivery-status.status-deleted {
  background-color: #a10000;
}

.delivery-status.status-pending {
  background-color: #6da0be;
}

.delivery-status.status-progress {
  background-color: #6da0be;
}

.delivery-status.status-dispatched {
  background-color: #6da0be;
}

.delivery-status.status-delivered {
  background-color: #89c882;
}

.delivery-status.status-lost {
  background-color: #a10000;
}

.delivery-status.status-refused {
  background-color: #a10000;
}

.delivery-status.status-returned {
  background-color: #a10000;
}

.delivery-status.status-active {
  background-color: #89c882;
}

.delivery-status.status-end {
  background-color: #ababab;
}

.delivery-status.status-open {
  background-color: #e8a541;
}

.delivery-status-name {
  margin-left: 20px;
}

.calc-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.calc-icon .arrow-down,
.calc-icon .arrow-up {
  display: none;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}

.calc-icon:hover {
  cursor: pointer;
}

.calc-icon:hover .arrow-down {
  display: inline-block;
}

.calc-icon .calculator.opened:hover+.arrow-up+.arrow-down {
  display: none;
}

.calc-icon .calculator.opened+.arrow-up {
  display: inline-block;
}

.calculator-saving-layer {
  display: none;
  background-color: #f0eff0;
  padding: 8px;
}

.calculator-saving-layer.opened {
  display: block;
}

.calculator-saving-layer .saving-box {
  background-color: #3b3b3b;
  color: #fff;
}

.calculator-saving-layer .button-layer {
  padding: 15px 0;
}

@media (max-width: 767px) {
  .calculator-saving-layer .button-layer a[href="#calculator_details"] div {
    margin-bottom: 7px;
  }

  .calculator-saving-layer .button-layer .back {
    bottom: -17px;
    right: -3px;
  }
}

.calculator-saving-layer .button-layer .back {
  font-size: 25px;
}

.calculator-saving-layer .button-layer .back:hover {
  cursor: pointer;
}

.calculator-saving-layer .button-layer a:first-child div {
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 2px 0;
  margin-bottom: 5px;
}

.calculator-saving-layer .button-layer a:first-child div span {
  color: #802538;
}

.calculator-saving-layer .button-layer a:nth-child(2) div {
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 2px 0;
}

.calculator-saving-layer .button-layer a:nth-child(2) div span {
  color: #802538;
}

.calculator-saving-layer .saving-box,
.calculator-saving-layer .costs-box,
.calculator-saving-layer .uvp-box,
.calculator-saving-layer .button-layer {
  margin: 0 0 10px 0;
}

@media (max-width: 767px) {
  .calculator-saving-layer .saving-box,
  .calculator-saving-layer .costs-box,
  .calculator-saving-layer .uvp-box,
  .calculator-saving-layer .button-layer {
    padding-bottom: 5px;
  }
}

.calculator-saving-layer .text-left {
  text-align: left;
}

.calculator-saving-layer .text-right {
  text-align: right;
}

.catalog-subnavigation {
  border: 1px solid #d2d2d2;
}

.catalog-subnavigation .sub-navigation-content {
  padding: 20px;
}

.catalog-subnavigation .sub-navigation-content .sub-navigation-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.catalog-subnavigation .sub-navigation-content .sub-navigation-image-container img {
  width: 100%;
}

.catalog-subnavigation .sub-navigation-content .sub-navigation-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.catalog-subnavigation .sub-navigation-content .sub-navigation-text-container span {
  text-align: center;
}

.pop-up-layer {
  background-color: #000;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  transition: opacity .6s ease-in-out;
}

.pop-up-layer.open {
  opacity: .7;
  z-index: 10;
}

.pop-up-form {
  position: fixed;
  top: 25px;
  z-index: -1;
  left: 50%;
  width: 0;
  opacity: 0;
  transform: translate(-50%, 100%);
  transition: all .3s ease-in-out;
  bottom: 25px;
}

.pop-up-form label {
  font-weight: 700;
  margin: 0;
}

.pop-up-form.open {
  transform: translate(-50%);
  opacity: 1;
  z-index: 999;
  width: 100%;
}

.pop-up-form .container {
  padding-top: 80px;
}

@media (max-width: 767px) {
  .pop-up-form .container {
    padding-top: 110px;
  }
}

.pop-up-form .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 23px;
  color: #fff;
  padding: 15px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  margin-left: -1px;
}

.pop-up-form .popup-body {
  overflow-y: scroll;
  max-height: 87vh;
  overflow-x: hidden;
}

.pop-up-form .pop-up-container {
  padding: 30px 15px;
  background-color: #fff;
  width: 100%;
}

.pop-up-form .pop-up-container .pop-up-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pop-up-form .pop-up-container .pop-up-btn-group span {
  font-size: 17px;
}

@media (max-width: 767px) {
  .pop-up-form .pop-up-container .pop-up-btn-group {
    display: block;
  }
}

.pop-up-form .order-tracking.col-sm-6 {
  padding: 0;
}

.pop-up-form .pop-up-headline {
  display: flex;
  justify-content: space-between;
}

.pop-up-form .pop-up-headline span.popup-back {
  font-size: 25px;
  font-weight: 400;
  margin-right: 15px;
  color: #802538;
}

.pop-up-form .pop-up-headline span.popup-back:hover {
  cursor: pointer;
}

.history-list order-history>.closebtn {
  display: none;
}

.scrollable {
  max-height: 100%;
  overflow-y: scroll;
  background: #fff;
}

.icon.delete {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.icon.closebtn {
  width: 25px;
  height: 25px;
}

.accordion {
  border: 1px solid #e3e3e3;
  margin: 10px 0;
}

.accordion .accordion-header {
  background-color: #e3e3e3;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 700;
  width: calc(100% + 1px);
  align-items: center;
}

.accordion .accordion-header:hover {
  cursor: pointer;
}

.accordion .accordion-body {
  display: none;
  padding: 20px 10px;
}

.accordion .accordion-body.open {
  display: block;
}

.pop-up-form .product {
  padding-top: 20px;
  margin-bottom: 20px;
}

.pop-up-form .product img {
  width: 100%;
  height: auto;
}

@media (min-width: 48em) {
  .pop-up-form .product .product-content {
    padding-right: 7rem;
  }
}

.pop-up-form .create-message {
  border: 0 none;
  padding: 10px 70px;
  margin: 30px 0;
}

.pop-up-form .create-message.closed {
  display: none;
}

.pop-up-form .ticket-update {
  display: none;
  border: 1px solid #802538;
  margin-bottom: 30px;
}

.pop-up-form .ticket-update form {
  margin-right: 3px;
}

.pop-up-form .ticket-update.open {
  display: block;
}

.pop-up-form .ticket-update .form-footer {
  padding: 20px;
}

.pop-up-form .ticket-update .send-btn {
  border-radius: 20px;
  border: 0 none;
  padding: 0 55px;
}

.pop-up-form .ticket-update .cancel {
  padding: 0 45px;
  margin-right: 5px;
  display: inline-block;
}

.pop-up-form .ticket-update .cancel:hover {
  cursor: pointer;
}

.pop-up-form .ticket-update .form-header {
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 700;
  width: calc(100% + 1px);
}

.pop-up-form .ticket-update #file-upload {
  display: none;
}

.pop-up-form .ticket-update label[for=file-upload] {
  font-size: 18px;
}

.pop-up-form .ticket-update label[for=file-upload]:hover {
  cursor: pointer;
}

.pop-up-form .ticket-update textarea {
  width: 100%;
  height: 100%;
  border: 0 none;
  margin: 1px;
  border-bottom: 1px solid #e3e3e3;
  min-height: 200px;
  padding: 20px;
}

.accordion-header .arrow {
  transition: transform .3s ease-in-out;
}

.accordion-header.open .arrow {
  transform: rotateX(180deg);
}

.state {
  display: flex;
  align-items: center;
}

.state .type1,
.state .type4,
.state .type11,
.state .type12,
.state .type15,
.state .type17,
.state .type20,
.state .type23,
.state .type10 {
  background-color: #6da0be;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.state .type18,
.state .type19,
.state .type22,
.state .type14 {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: #e8a541;
}

.state .type16,
.state .type2,
.state .type13,
.state .type21 {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: #89c882;
}

.ticket {
  background-color: #802538;
  padding: 10px 5px;
  margin-bottom: 1px;
}

.ticket .f-big {
  font-size: 23px;
}

.ticket .state {
  font-size: 17px;
}

.ticket td:first-child {
  padding: 10px;
}

.ticket .ticket-articles {
  position: relative;
}

.ticket .ticket-articles label[for=file-upload] {
  position: absolute;
  bottom: 0;
  left: 15px;
}

.ticket .ticket-articles .ticket-message {
  margin-bottom: 20px;
  word-break: break-word;
}

.ticket .ticket-articles .ticket-content {
  padding-bottom: 45px;
}

.ticket .ticket-articles .ticket-content span {
  margin-bottom: 5px;
}

.page-breadcrumb {
  padding-bottom: 24px;
  padding-left: 10px;
  border-bottom: 1px dashed #e3e3e3;
  width: 100%;
  margin-bottom: 50px;
  font-size: 17px;
  color: #3b3b3b;
}

.page-breadcrumb span {
  display: inline-block;
}

.page-breadcrumb span.breadcrumb-name {
  margin-left: 18px;
}

.auth {
  width: 90%;
  min-height: 18em;
  margin: 0 auto;
}

.auth .striking {
  margin-top: 10px;
  padding: 15px;
  margin-bottom: 10px;
  font-weight: 200;
  width: 100%;
}

.auth input.striking {
  border: 1px solid #581a27;
  font-size: 18px;
  padding: 20px;
}

.auth .forgot-password {
  text-align: right;
}

.auth .forgot-password a {
  font-size: 1rem;
}

.auth .buttons {
  text-align: right;
  margin-top: 40px;
}

.auth .buttons .button {
  display: block;
  margin-top: 0;
  color: #fff;
  transition: ease all .2s;
  text-align: center;
  font-size: 1.2rem;
}

@media (min-width: 64em) {
  .auth .buttons .button {
    margin-top: 50px;
  }
}

@media (min-width: 64em) {
  .auth .buttons {
    margin-top: 0;
  }
}

@media (min-width: 64em) {
  .auth {
    width: 35em;
  }
}

@media (max-width: 767px) {
  .auth {
    width: 100%;
  }
}

.header-height {
  height: 179px;
}

.header-right {
  height: 150px;
}

header.header>.container {
  padding-bottom: 15px;
  padding-top: 15px;
}

@media (max-width: 767px) {
  header.header.plain .header--logo a {
    text-align: center;
  }
}

header.header .plain-welcome {
  align-self: center;
}

header.header .plain-welcome h1 {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  header.header .plain-welcome {
    margin: 25px 0 5px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  header.header .login .header--logo {
    padding-bottom: 15px;
  }
}

header.header .header-left .header--menu-button {
  position: relative;
  width: 100%;
}

@media (max-width: 767px) {
  header.header .header--logo {
    padding-right: 7px;
  }
}

header.header .header--logo a {
  display: block;
  width: 100%;
  text-align: right;
}

@media (min-width: 48em) {
  header.header .header--logo a {
    text-align: left;
  }
}

header.header .header--logo img {
  max-width: 200px;
  position: relative;
  max-height: 125px;
}

@media (min-width: 64em) {
  header.header .header--logo img {
    max-width: 100%;
  }
}

header.header img {
  max-width: 100%;
}

header.header .catalog-filter-search .value,
header.header .catalog-filter-search .reset {
  border-color: #581a27;
}

header.header .catalog-filter-search .btn-primary {
  padding: 0;
  text-align: center;
}

header.header .catalog-filter-search .btn-primary .search {
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto;
}

header.header .brand--logo {
  text-align: right;
  padding: 10px 0;
  margin-top: 15px;
}

header.header .brand--logo img {
  max-width: 175px;
}

@media (min-width: 768px)and (max-width: 991px) {
  header.header .brand--logo img {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  header.header {
    background-color: #fff;
  }
}

header.header .calculate_leasing_box {
  padding-left: 20px;
}

header.header .calculate_leasing_box .calculate_leasing:hover .calculator-bubble {
  transform: scale(1.2);
}

header.header .calculate_leasing_box .calculate_leasing .calculate_leasing_text {
  background-color: #ececec;
  font-size: 13px;
  padding: 4px 4px 4px 25px;
}

@media (min-width: 64em) {
  header.header .calculate_leasing_box .calculate_leasing .calculate_leasing_text {
    padding: 4px 4px 4px 38px;
  }
}

@media (max-width: 767px) {
  header.header .calculate_leasing_box .calculate_leasing .calculate_leasing_text {
    padding: 5px 10px 5px 35px;
    font-size: 12px;
  }
}

header.header .calculate_leasing_box .calculate_leasing .calculate_leasing_text span {
  color: #802538;
  font-weight: 700;
}

header.header .calculate_leasing_box .calculate_leasing .calculate_leasing_text .calculator-bubble {
  position: absolute;
  width: 48px;
  height: 48px;
  left: 0;
  top: -9px;
  transition: .3s transform ease;
}

@media (max-width: 767px) {
  header.header .calculate_leasing_box .calculate_leasing .calculate_leasing_text .calculator-bubble {
    left: 10px;
  }
}

@media (max-width: 767px) {
  header.header .calculate_leasing_box .calculate_leasing {
    margin-bottom: 20px;
  }
}

.alternate_header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 145px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.alternate_header img {
  margin-top: auto;
  margin-bottom: auto;
}

.footer {
  padding: 25px 0;
  background-color: #f0eff0;
}

.footer h2 {
  font-size: 24px;
  text-transform: uppercase;
}

.footer a {
  color: #802538;
}

.footer ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.footer .fb-icon {
  background-image: url("../images/FB-fLogo_weiss.svg");
  width: 43px;
  height: 43px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.footer .fb-link-text {
  display: inline-block;
  font-size: 17px;
  font-weight: lighter;
  line-height: 20px;
  text-transform: none;
  padding-left: 15px;
}

.footer .fb-link {
  display: flex;
}

.footer .yammer-icon {
  width: 40px;
  height: 40px;
}

@media (max-width: 767px) {
  .footer {
    margin-top: 50px;
  }
}

.footer .footer-menu--item {
  padding-bottom: 10px;
}

.main-navigation {
  background-color: #802538;
  display: none;
  min-height: 45px;
  z-index: 10;
  position: relative;
}

.main-navigation .catalog-filter .catalog-filter-tree {
  margin: 0;
}

.main-navigation .catalog-filter .catalog-filter-tree a.cat-item {
  border: none;
}

.main-navigation .catalog-filter .catalog-filter-tree a.cat-item span {
  vertical-align: text-top;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul {
  display: block;
  margin-bottom: 0;
  padding: 0;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 200;
  display: block;
  margin-left: 0;
  margin-right: 0px;
  padding: 0;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li a {
  color: #fff;
  text-decoration: none;
  transition: ease all .1s;
  padding: 10px 12px;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li.active,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li:hover {
  color: #fff;
  background-color: #581a27;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li.active>ul,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul {
  opacity: 0;
  transition: ease all .2s;
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  border: 1px solid #d2d2d2;
  border-top: none;
  padding: .5rem;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul li a {
  color: #3b3b3b;
  text-transform: initial;
  padding: 5px 20px;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul li a:hover {
  background: inherit;
  color: #581a27;
}

@media (min-width: 48em) {
  .main-navigation .catalog-filter .catalog-filter-tree>ul>li {
    margin-left: auto;
    display: inline-block;
  }
}

@media (min-width: 48em) {
  .main-navigation {
    display: block;
  }
}

@media (max-width: 767px) {
  #menu.catalog-filter-tree ul {
    padding-left: 0;
  }
}

ul.meta-navigation {
  padding-left: 0;
  list-style: none;
  text-align: center;
  display: none;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
}

ul.meta-navigation .meta-navigation--item {
  font-weight: 200;
  font-size: 1rem;
  display: inline-block;
}

ul.meta-navigation .meta-navigation--item span {
  display: inline-block;
}

ul.meta-navigation .meta-navigation--item.active {
  background-color: #fff;
}

ul.meta-navigation .meta-navigation--item.active span {
  display: inline-block;
  border-bottom: 2px solid #050505;
}

ul.meta-navigation .meta-navigation--item.admin {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.meta-navigation .meta-navigation--item.admin svg {
  margin-right: 5px;
}

ul.meta-navigation .meta-navigation--item.logout-block {
  margin-right: 60px;
}

ul.meta-navigation .meta-navigation--item .logout {
  background-color: #802538;
  border-radius: 20px;
  padding: 1px 12px;
  color: #fff;
  font-size: 12px;
  margin: 0 5px;
  font-weight: 400;
}

ul.meta-navigation .meta-navigation--item .logout:hover {
  color: #fff;
}

ul.meta-navigation .meta-navigation--item a {
  padding: 10px 5px;
  display: block;
  text-decoration: none;
  color: #3b3b3b;
  position: relative;
}

ul.meta-navigation .meta-navigation--item a .warenkorb {
  margin-left: 5px;
}

ul.meta-navigation .meta-navigation--item a:hover {
  color: #802538;
}

ul.meta-navigation .meta-navigation--item .meta-navigaton--basket {
  position: absolute;
  right: -10px;
  top: 0;
  background-color: #ff321b;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

@media (max-width: 767px) {
  ul.meta-navigation .meta-navigation--item .meta-navigaton--basket {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 48em) {
  ul.meta-navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

#menu.mm-menu_opened~.header .mobile-menu--button span:first-child {
  top: 50%;
  transform: rotate(45deg);
  position: absolute;
}

#menu.mm-menu_opened~.header .mobile-menu--button span:nth-child(2) {
  opacity: 0;
}

#menu.mm-menu_opened~.header .mobile-menu--button span:last-child {
  transform: rotate(-45deg);
  top: 50%;
}

#menu.mm-menu_opened .cart.mm-listitem .mm-listitem__text .meta-navigaton--basket {
  position: absolute;
  right: -10px;
  top: 0;
  background-color: #ff321b;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

@media (max-width: 767px) {
  #menu.mm-menu_opened .cart.mm-listitem .mm-listitem__text .meta-navigaton--basket {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

#menu.mm-menu_opened .mm-panels>div {
  background: #fff;
}

#menu.mm-menu_opened .mm-panels>div .mm-navbar {
  background: #fff;
}

#menu.mm-menu_opened .mm-panels>div ul li a.cat-item {
  border: none;
  padding: 12px 10px 12px 20px;
}

.logo {
  margin: 20px 0;
}

.logo .mobile-menu--button {
  height: 22px;
  width: 30px;
  position: relative;
  display: inline-block;
}

.logo .mobile-menu--button span:first-child {
  height: 2px;
  width: 30px;
  background: #581a27;
  display: block;
  transition: transform .2s,top .3s ease;
}

.logo .mobile-menu--button span:nth-child(2) {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  opacity: 1;
  background: #581a27;
  transform: translateY(-50%) translateX(0);
  transition: opacity .2s ease .1s;
}

.logo .mobile-menu--button span:last-child {
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 2px;
  background: #581a27;
  transition: transform .4s,top .3s ease;
}

@media (min-width: 48em) {
  .logo .mobile-menu--button {
    display: none;
  }
}

@media (min-width: 64em) {
  .logo {
    margin: 0;
  }
}

@media (min-width: 48em) {
  .logo {
    justify-content: left;
    align-items: flex-start;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .logo {
    margin: 30px 0;
  }

  .logo .col-md-12 {
    position: absolute;
    left: 0;
    top: 5px;
  }
}

@media (max-width: 767px) {
  .header-logo {
    padding: 15px;
    margin-bottom: 15px;
  }
}

.header-logo .mobile-menu--button {
  height: 22px;
  width: 30px;
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}

.header-logo .mobile-menu--button span:first-child {
  height: 2px;
  width: 30px;
  background: #581a27;
  display: block;
  transition: transform .2s,top .3s ease;
}

.header-logo .mobile-menu--button span:nth-child(2) {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 2px;
  opacity: 1;
  background: #581a27;
  transform: translateY(-50%) translateX(0);
  transition: opacity .2s ease .1s;
}

.header-logo .mobile-menu--button span:last-child {
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 2px;
  background: #581a27;
  transition: transform .4s,top .3s ease;
}

@media (min-width: 48em) {
  .header-logo .mobile-menu--button {
    display: none;
  }
}

.catalog-list .list-search {
  margin: 50px 0;
  text-align: center;
}

.catalog-list .product a {
  max-width: 100%;
}

.catalog-list .product a .media-list {
  height: 240px;
  position: relative;
  background: #fff;
}

.catalog-list .product a .media-list .bu_media-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.catalog-list .product a .media-list .bu_media-item:nth-child(1) {
  opacity: 1;
}

.catalog-list .product a .media-list .bu_media-item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
}

.catalog-list .product a .media-list:hover .bu_media-item:nth-child(1) {
  opacity: 0;
}

.catalog-list .product a .media-list:hover .bu_media-item:nth-child(2) {
  opacity: 1;
  z-index: 999;
}

.catalog-list .product a .offer {
  max-width: 100%;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .catalog-list .product {
    margin-bottom: 55px;
  }
}

.media-list {
  height: 240px;
  position: relative;
  background: #fff;
  margin: 0 auto;
}

.media-list .bu_media-item {
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.media-list .bu_media-item:nth-child(1) {
  opacity: 1;
}

.media-list .bu_media-item img {
  max-height: 100%;
  top: 0;
  left: 0;
  transform: initial;
}

.media-list:hover .bu_media-item:nth-child(2) {
  opacity: 1;
  z-index: 999;
}

.pagination {
  margin: 30px 0 60px 0;
}

.pagination .browser,
.pagination .limit,
.pagination .sort {
  margin: 0 0 15px 0;
}

@media (max-width: 767px) {
  .pagination .browser,
  .pagination .limit,
  .pagination .sort {
    max-width: 100%;
  }
}

.pagination .limit,
.pagination .sort {
  text-align: left;
  width: auto;
  position: relative;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination .limit .limit-select,
.pagination .limit .sort-select,
.pagination .sort .limit-select,
.pagination .sort .sort-select {
  overflow: hidden;
  border: 1px solid #f0eff0;
  height: 45px;
  padding: 10px 15px;
  cursor: pointer;
  width: auto;
  display: inline-flex;
}

.pagination .limit .limit-select .arrow-down,
.pagination .limit .sort-select .arrow-down,
.pagination .sort .limit-select .arrow-down,
.pagination .sort .sort-select .arrow-down {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.pagination .limit .limit-select .value,
.pagination .limit .sort-select .value,
.pagination .sort .limit-select .value,
.pagination .sort .sort-select .value {
  margin-left: 5px;
  font-weight: 700;
}

.pagination .limit .limit-select-content,
.pagination .limit .sort-select-content,
.pagination .sort .limit-select-content,
.pagination .sort .sort-select-content {
  display: none;
  border: 1px solid #f0eff0;
  position: absolute;
  right: 0;
  top: 44px;
  z-index: 15;
  background: #fff;
  text-align: center;
  width: auto;
  border-top: 1px solid #fff;
  line-height: 30px;
}

.pagination .limit ul,
.pagination .sort ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pagination .limit ul .active,
.pagination .sort ul .active {
  font-weight: 700;
}

.pagination .browser-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .browser-container .browser {
  border: 1px solid #f0eff0;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

.pagination .browser-container .browser span {
  border-right: 1px solid #f0eff0;
  padding: 10px 15px;
}

@media (max-width: 767px) {
  .pagination .browser-container .browser span {
    padding: 15px 10px;
    font-size: 12px;
  }
}

.pagination .browser-container .browser a {
  border-right: 1px solid #f0eff0;
  padding: 10px 15px;
}

.pagination .browser-container .browser a:last-child {
  border: none;
}

@media (max-width: 767px) {
  .pagination .browser-container .browser a {
    padding: 10px 10px;
  }
}

.pagination .browser-container .browser .next,
.pagination .browser-container .browser .prev {
  display: inline-flex;
  align-items: center;
}

.pagination .browser-container .browser .next span,
.pagination .browser-container .browser .prev span {
  border: none;
  padding: 0;
}

@media (max-width: 767px) {
  .pagination .browser-container .browser .next span.name,
  .pagination .browser-container .browser .prev span.name {
    display: none;
  }
}

.pagination .browser-container .browser .next {
  padding-right: 5px;
}

.pagination .browser-container .browser .next .arrow-right {
  height: 20px;
  width: 20px;
}

@media (max-width: 767px) {
  .pagination .browser-container .browser .next {
    padding-right: 10px;
  }
}

.pagination .browser-container .browser .prev {
  padding-left: 5px;
}

.pagination .browser-container .browser .prev .arrow-left {
  height: 20px;
  width: 20px;
}

@media (max-width: 767px) {
  .pagination .browser-container .browser .prev {
    padding-left: 10px;
  }
}

.pagination .browser-container .browser .current {
  font-weight: 700;
}

.pagination .limit-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .limit-container .limit .limit-select-content {
  padding: 10px 35px 10px 35px;
  width: 100%;
}

.pagination .sort-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .sort-container .sort .sort-select-content {
  padding: 10px 15px 10px 15px;
  text-align: left;
}

.pagination .sort-container .sort .sort-select-content li {
  display: inline-flex;
  align-items: center;
}

.pagination .sort-container .sort .sort-select-content li .arrow-down,
.pagination .sort-container .sort .sort-select-content li .arrow-up {
  display: inline-block;
  width: 25px;
  height: 25px;
}

@media (min-width: 64em) {
  .pagination .sort-container {
    justify-content: flex-end;
  }

  .pagination .browser-container {
    justify-content: flex-start;
  }

  .pagination .limit-container {
    justify-content: flex-end;
  }
}

.catalog-detail {
  margin-top: 30px;
}

.catalog-detail .caption.description {
  display: none;
}

.catalog-detail .header {
  padding: 0 1em;
}

.catalog-detail .delivery-info {
  font-size: 12px;
}

.catalog-detail .calculator-teaser {
  border-bottom: 1px dashed #f0eff0;
  border-top: 1px dashed #f0eff0;
  padding: 50px 0;
  display: flex;
}

.catalog-detail .calculator-teaser a {
  display: flex;
  align-items: center;
  background: #802538;
  color: #fff !important;
  font-size: 18px;
  width: 100%;
  justify-content: center;
  padding: 15px;
}

.catalog-detail .calculator-teaser a:hover {
  background: #581a27;
}

.catalog-detail .calculator-teaser a .edit--icon {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 10px;
}

.catalog-detail .catalog-detail-basic h1.name {
  font-size: 22px;
  color: #3b3b3b;
  text-transform: inherit;
  font-weight: 700;
  height: auto;
  margin-bottom: 5px;
}

.catalog-detail .catalog-detail-basic .short {
  display: block;
}

.catalog-detail .catalog-detail-additional {
  margin-top: 50px;
}

.catalog-detail .catalog-detail-additional .header {
  position: relative;
}

.catalog-detail .catalog-detail-additional .header .plus,
.catalog-detail .catalog-detail-additional .header .minus {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.catalog-detail .catalog-detail-additional .header .plus {
  display: block;
}

.catalog-detail .catalog-detail-additional .header .minus {
  display: none;
}

.catalog-detail .catalog-detail-additional .header:after {
  content: "";
}

.catalog-detail .catalog-detail-additional .header.opened .minus {
  display: block;
}

.catalog-detail .catalog-detail-additional .header.opened .plus {
  display: none;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-additional .header {
    padding-left: 5px;
  }
}

.catalog-detail .catalog-detail-image figcaption {
  display: none;
}

.catalog-detail .catalog-detail-image .bu-image-single,
.catalog-detail .catalog-detail-image .image-single {
  height: 400px;
}

.catalog-detail .catalog-detail-image .bu-image-single .media-item,
.catalog-detail .catalog-detail-image .image-single .media-item {
  height: 400px;
}

.catalog-detail .catalog-detail-image .thumbs-horizontal .slick-next {
  position: relative;
  background-image: none;
}

.catalog-detail .catalog-detail-image .thumbs-horizontal .slick-next svg {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.catalog-detail .catalog-detail-image .thumbs-horizontal .slick-prev {
  position: relative;
  background-image: none;
}

.catalog-detail .catalog-detail-image .thumbs-horizontal .slick-prev svg {
  width: 40px;
  height: 40px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.catalog-detail .catalog-detail-basket .price-list {
  text-align: right;
  background-color: unset;
  color: #3b3b3b;
  font-weight: 400;
  padding-top: 0;
  font-size: 1rem;
}

.catalog-detail .catalog-detail-basket .price-list .value {
  font-size: 28px;
  font-weight: 700;
}

.catalog-detail .catalog-detail-basket .all-round-protection {
  display: flex;
  margin-top: 35px;
}

.catalog-detail .catalog-detail-basket .all-round-protection svg {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 5px;
}

.catalog-detail .catalog-detail-basket .all-round-protection .all-round-protection--text {
  display: inline-block;
}

.catalog-detail .catalog-detail-basket .all-round-protection a {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #802538;
}

.catalog-detail .catalog-detail-basket .stock-list {
  text-align: left;
}

.catalog-detail .catalog-detail-basket .stock-list .stockitem {
  display: block;
  margin: auto 0;
}

.catalog-detail .catalog-detail-basket .stock-list .stockitem .stock-level--text,
.catalog-detail .catalog-detail-basket .stock-list .stockitem .stocktext {
  display: inline-block;
  font-weight: 700;
}

.catalog-detail .catalog-detail-basket .stock-list .stockitem .stock-level--text {
  color: #3b3b3b;
}

.catalog-detail .catalog-detail-basket .stock-list .stockitem .stocktext {
  margin-left: 5px;
}

.catalog-detail .catalog-detail-basket .addbasket {
  margin-top: 2.5em;
  font-weight: 400;
}

.catalog-detail .catalog-detail-basket .addbasket button {
  width: 100%;
  padding: 10px 50px;
  margin: 0 0 0 auto;
}

@media (min-width: 48em) {
  .catalog-detail .catalog-detail-basket .addbasket button {
    width: 50%;
  }
}

.catalog-detail--stage {
  border-bottom: 1px dashed #f0eff0;
}

.catalog-detail--stage .catalog-stage-breadcrumb {
  display: inline-block;
}

.catalog-detail--stage .catalog-stage-image {
  display: none;
}

.catalog-detail--stage .catalog-stage-navigator {
  display: inline-block;
  float: right;
}

.catalog-detail--stage .catalog-stage-navigator nav {
  display: flex;
  align-items: center;
}

.catalog-detail--stage .catalog-stage-navigator nav .next {
  position: relative;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.catalog-detail--stage .catalog-stage-navigator nav .next:after {
  content: "";
}

.catalog-detail--stage .catalog-stage-navigator nav .next .arrow-right {
  height: 25px;
  width: 25px;
}

.catalog-detail--stage .catalog-stage-navigator nav .prev {
  position: relative;
  font-size: 1rem;
}

.catalog-detail--stage .catalog-stage-navigator nav .prev:before {
  content: "";
}

.catalog-detail--stage .catalog-stage-navigator nav .prev .arrow-left {
  height: 25px;
  width: 25px;
}

@media (max-width: 767px) {
  .catalog-detail--stage .catalog-stage-navigator {
    float: none;
  }
}

@media (max-width: 767px) {
  .catalog-detail--stage {
    text-align: center;
    padding-bottom: 30px;
  }
}

.catalog-detail .product .price-list {
  display: flex;
  justify-content: flex-end;
}

.catalog-detail .product .price-list .price-actual {
  width: 100%;
}

.catalog-detail .product .price-list .price-actual .netto-text {
  font-weight: 400;
  font-size: 14px;
}

.catalog-detail .product .price-list .price-actual .price-text .value {
  font-weight: 400;
  font-size: 46px;
  margin: 0;
  display: inline;
  vertical-align: middle;
}

.catalog-detail .product .price-list .price-actual .price-text .value #loader-dots.isloading span {
  background-color: #000;
}

.catalog-detail .product .price-list .price-actual .currency {
  font-size: 46px;
  vertical-align: middle;
}

.catalog-detail .product .price-list .price-actual .ordernow__button-text {
  display: none;
}

.catalog-detail .product .price-list:hover .price-text {
  margin-top: 0;
}

.catalog-detail .product .calculate.btn-primary--rounded {
  padding: 0;
  width: 25%;
  color: #fff;
  font-size: 17px;
}

@media (max-width: 767px) {
  .catalog-detail .product .calculate.btn-primary--rounded {
    width: 100%;
  }
}

.catalog-detail .product .info-small {
  width: 30px;
  height: 30px;
  display: block;
}

.catalog-detail .product .catalog-detail-basket .stock-high,
.catalog-detail .product .catalog-detail-basket .stock-unlimited {
  color: #89c882;
}

.catalog-detail .content.attributes {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
}

.catalog-detail .content.attributes .item {
  width: 25%;
}

.catalog-detail .content.attributes .item #attrValue_1 {
  word-break: break-all;
}

.catalog-detail .content .attr-headline {
  color: #802538;
  text-decoration: underline;
  font-weight: 700;
}

.catalog-detail .catalog-detail-image .thumbs-horizontal .thumbs {
  width: calc(100% - 87px);
}

.detail-service {
  padding-bottom: 20px;
  text-align: left;
}

.detail-service .servive-header {
  display: flex;
  padding-bottom: 15px;
  align-content: baseline;
}

.detail-service .servive-header span {
  align-self: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.detail-service .btn {
  border-radius: 25px;
  width: auto;
  font-size: 16px;
  margin-right: 15px;
}

.detail-service .info {
  align-self: center;
  width: 25px;
}

.detail-service .info .info-text.left {
  font-size: 16px;
  left: -238px;
}

.catalog-stage-breadcrumb ol {
  margin: 0;
  padding: 0;
}

.catalog-stage-breadcrumb .title {
  margin-right: 10px;
}

.catalog-stage-breadcrumb li {
  white-space: inherit;
}

.catalog-stage-breadcrumb li:after {
  content: " － ";
}

.catalog-filter-attribute {
  margin: 2em 0;
}

.catalog-filter-attribute .attribute-selected .selected-all,
.catalog-filter-attribute .attribute-selected .attr-name {
  border-radius: 4px;
  padding: 2px 8px;
  transition: ease all .3s;
}

.catalog-filter-attribute .attribute-selected .selected-all:hover,
.catalog-filter-attribute .attribute-selected .attr-name:hover {
  border-color: #802538;
}

.catalog-filter-attribute .attribute-lists {
  display: block;
}

.catalog-filter-attribute .attribute-lists .attr-list {
  height: auto;
  display: none;
}

.catalog-filter-attribute .attribute-lists .attr-item.disabled {
  display: none;
}

.catalog-filter-attribute .attribute-lists .attr-item .attr-count {
  display: none;
}

.catalog-filter-attribute label:before {
  width: 17px;
  height: 17px;
  line-height: 15px;
  font-size: 14px;
  text-align: center;
}

.catalog-filter-attribute fieldset {
  border: none;
  padding: 0 .5em;
  width: 25%;
  margin: .5em 0;
}

.catalog-filter-attribute fieldset legend {
  text-align: center;
}

@media (max-width: 767px) {
  .catalog-filter-attribute fieldset {
    width: 100%;
  }
}

.catalog-filter-attribute legend {
  width: 100%;
  background-color: #802538;
  padding: 10px 0;
  color: #fff;
  border: none;
  font-weight: 200;
  transition: ease all .3s;
}

.catalog-filter-attribute legend:hover {
  background-color: #581a27;
}

.catalog-filter-attribute legend:before {
  content: none;
}

.catalog-filter-attribute legend:after {
  content: " ▼";
  font-size: 8px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: 5px;
}

.catalog-filter-attribute.cat_10 .attr-1036619 {
  display: none !important;
}

.catalog-filter-attribute.cat_10 .attr-449474 {
  display: none !important;
}

.catalog-filter-attribute.cat_12 .attr-1036619 {
  display: none !important;
}

.catalog-filter-attribute.cat_12 .attr-449474 {
  display: none !important;
}

.catalog-filter-attribute.cat_14 .attr-1028791 {
  display: none !important;
}

.catalog-filter-attribute.cat_14 .attr-1029156 {
  display: none !important;
}

.catalog-filter-attribute.cat_16 .attr-1029156 {
  display: none !important;
}

.catalog-filter-attribute.cat_16 .attr-1029156 {
  display: none !important;
}

.ui-widget-content {
  z-index: 9999;
  width: 393px !important;
}

@media (max-width: 767px) {
  .ui-widget-content {
    display: none !important;
  }
}

.aimeos.catalog-suggest .suggest-item {
  justify-content: center;
  display: flex;
  align-items: center;
}

.aimeos.catalog-suggest .suggest-item .item-name {
  width: 75%;
  font-size: 13px;
  padding: 0 5px;
}

.aimeos.catalog-suggest .suggest-item .item-image {
  width: 25%;
  margin: 5px 0;
}

.catalog-detail-similar .product .price-list,
.last-seen .product .price-list {
  padding: 15px 0;
  background-color: #802538;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  height: 26px;
}

.catalog-detail-similar .product .price-list:hover .price-text,
.last-seen .product .price-list:hover .price-text {
  margin-top: -26px;
}

.catalog-detail-similar .product .price-list .price-text,
.last-seen .product .price-list .price-text {
  transition: all .3s ease;
  padding-top: 2px;
}

.catalog-detail-similar .product .mpp-price-item,
.last-seen .product .mpp-price-item {
  height: 26px;
  overflow: hidden;
}

.catalog-detail-similar .product h3.name,
.last-seen .product h3.name {
  font-size: 19px;
  text-transform: inherit;
  font-weight: 700;
  color: #3b3b3b;
  line-height: 23px;
  margin-bottom: 0;
  height: 80px;
}

.aimeos .catalog-detail-similar .product {
  border-bottom: 0 none;
}

.aimeos .catalog-detail-similar .product .price-list {
  padding: 13px 0 15px 0;
  background-color: #802538;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}

.aimeos .catalog-detail-similar .product .price-list:hover .price-text {
  margin-top: -28px;
}

.aimeos .catalog-detail-similar .product .price-list .price-actual .price-text {
  transition: all .3s ease;
}

.aimeos .catalog-detail-similar .product .price-list .price-actual .price-text .value {
  font-size: 18px;
  font-weight: 200;
}

.aimeos .catalog-detail-similar .product .price-list .price-actual .price-text .currency {
  font-size: 18px;
}

.aimeos .catalog-detail-similar .product .price-list .price-actual .ordernow__button-text {
  display: block;
}

.aimeos .catalog-detail-similar .product .mpp-price-item {
  height: 26px;
  overflow: hidden;
}

.aimeos .catalog-detail-similar .product h3.name {
  font-size: 19px;
  text-transform: inherit;
  font-weight: 700;
  color: #3b3b3b;
  line-height: 23px;
  margin-bottom: 0;
  height: 80px;
}

.checkout-standard .button-group {
  display: block;
  overflow: hidden;
}

.checkout-standard .button-group .btn-default {
  float: left;
  margin-left: 0;
}

@media (max-width: 767px) {
  .checkout-standard .button-group .btn-default {
    float: none;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
}

.checkout-standard .button-group .btn-primary {
  float: right;
}

@media (max-width: 767px) {
  .checkout-standard .button-group .btn-primary {
    width: 100%;
    float: none;
    margin: 0;
  }
}

.checkout-standard .steps {
  margin: 20px 0 0;
  font-weight: 400;
}

.checkout-standard .steps .step {
  border-color: #f0eff0;
}

.checkout-standard .steps .step:before {
  content: counter(step) ". ";
  counter-increment: step;
}

.checkout-standard .steps .step.active {
  border-color: #d2d2d2;
}

.checkout-standard .steps .step.active:after {
  content: none;
}

.checkout-standard .steps .step.current {
  border-color: #3b3b3b;
}

@media (max-width: 767px) {
  .checkout-standard .steps .step {
    width: 32%;
  }
}

.checkout-standard .form-list .form-item label {
  margin-bottom: 0;
  margin-top: 15px;
}

.checkout-standard .form-list .form-item .form-control {
  margin-top: 5px;
}

.checkout-standard .form-list .optional,
.checkout-standard .form-list .mandatory {
  font-weight: 700;
}

.checkout-standard .form-list .success input,
.checkout-standard .form-list .success select {
  background-color: rgba(0,0,0,0);
}

.checkout-standard .checkout-standard-address label {
  font-weight: 700;
}

.checkout-standard .checkout-standard-address label span {
  font-weight: 400;
}

.checkout-standard .checkout-standard-address h2 {
  padding: 0 15px;
}

.checkout-standard .checkout-standard-address .flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.checkout-standard .checkout-standard-address .personal-info {
  margin-bottom: 60px;
}

.checkout-standard .checkout-standard-address .personal-info-row {
  padding-bottom: 5px;
}

.checkout-standard .checkout-standard-address .form-list .mandatory {
  display: block;
}

.checkout-standard .checkout-standard-address .item-address {
  flex: auto;
  padding: 0 15px;
  height: 100%;
  border: 1px solid #d5d5d5;
}

.checkout-standard .checkout-standard-address .item-address.item-new .header {
  min-height: 0;
  border: 0 none;
}

.checkout-standard .checkout-standard-address .item-address .header {
  background: none;
  height: 100%;
}

.checkout-standard .checkout-standard-address .item-address .header .btn-update {
  padding: 2px 40px;
}

.checkout-standard .checkout-standard-address .item-address .header .btn-update:hover {
  cursor: pointer;
}

.checkout-standard .checkout-standard-address .item-address .header input[type=radio] {
  border: 1px solid #581a27;
  margin-left: 22px;
}

.checkout-standard .checkout-standard-address .item-address .header #ca_mpp_deliveryoption-null {
  margin-left: 7px;
}

.checkout-standard .checkout-standard-address .item-address .header input:not([type=radio]) {
  border: 1px solid #f0eff0;
  margin: 5px 0 10px 0;
}

.checkout-standard .checkout-standard-address .item-address .header input:not([type=radio]):hover {
  border-color: #8d8d8d;
}

.checkout-standard .checkout-standard-address .item-address .header label.values {
  margin-left: 23px;
}

.checkout-standard .checkout-standard-address .item-address .header label span {
  margin-bottom: 4px;
}

.checkout-standard .checkout-standard-address .item-address .values {
  display: inline-flex;
  flex-direction: column;
}

.checkout-standard .checkout-standard-address .item-address .form-item.company {
  display: none !important;
}

.checkout-standard .checkout-standard-address .note {
  margin-top: 25px;
}

.checkout-standard .checkout-standard-address .checkout-standard-address-delivery {
  display: block;
}

.checkout-standard .checkout-standard-address .checkout-standard-address-billing {
  width: 100%;
}

.checkout-standard .checkout-standard-address .checkout-standard-address-billing .header {
  display: none;
}

.checkout-standard .checkout-standard-address .contact-info {
  margin-top: 82px;
}

.checkout-standard .checkout-standard-address .contact-info .contact-info-header {
  margin-bottom: 13px;
  text-transform: uppercase;
}

.checkout-standard .checkout-standard-delivery {
  display: none;
}

.checkout-standard .checkout-standard-payment *:not(.button-group) {
  display: none;
}

.checkout-standard .checkout-standard-summary .common-summary-address .item.payment {
  margin-bottom: 30px;
}

.checkout-standard .checkout-standard-summary .common-summary-address .item.payment .payment-body {
  background: #f0eff0;
  height: 100%;
}

.checkout-standard .checkout-standard-summary .common-summary-address .item.payment .payment-body .header {
  border-bottom: 1px solid #d2d2d2;
}

.checkout-standard .checkout-standard-summary .common-summary-address .item.payment .payment-body .header h3 {
  font-weight: 400;
}

.checkout-standard .checkout-standard-summary .common-summary-address .item.payment .payment-body .header .modify {
  background-color: rgba(0,0,0,0);
  color: #000;
  padding: 3px 20px;
}

.checkout-standard .checkout-standard-summary .common-summary-address .item.payment .payment-body .header,
.checkout-standard .checkout-standard-summary .common-summary-address .item.payment .payment-body .content {
  padding: 20px 30px;
  margin: 0;
}

.checkout-standard .checkout-standard-summary .common-summary-detail thead {
  background-color: #f0eff0;
}

.checkout-standard .checkout-standard-summary .common-summary-detail thead tr {
  border-bottom: 1px solid #d2d2d2;
}

.checkout-standard .checkout-standard-summary .common-summary-detail tbody tr {
  border-bottom: 1px solid #d2d2d2;
}

.checkout-standard .checkout-standard-summary .common-summary-detail tr.total {
  background-color: #f0eff0;
  border-top: 1px solid #d2d2d2;
}

.checkout-standard .checkout-standard-summary .common-summary-detail .header,
.checkout-standard .checkout-standard-summary .common-summary-detail .basket {
  margin: 0;
}

.checkout-standard .button-layer a:first-child {
  pointer-events: none;
}

.checkout-standard .button-layer a:first-child div {
  display: none;
}

.checkout-standard .checkout-standard-summary-option label {
  position: relative;
}

.checkout-standard .checkout-standard-summary-option label span.checkbox {
  left: 0;
}

.value-new {
  margin-left: 10px;
}

.mpp_deliveryoption {
  transition: max-height .3s ease-out;
  max-height: 0;
  overflow: hidden;
}

.mpp_deliveryoption.open {
  max-height: 1200px;
  margin-top: 40px;
}

.mpp_deliveryoption .btn-safe-new-address {
  margin: 30px 0;
}

.common-summary-detail .header a.modify {
  background-color: rgba(0,0,0,0);
  color: #000;
  padding: 3px 20px;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  background-size: 1.125rem 1.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}

.nuev-container {
  display: none;
  position: fixed;
  background: rgba(0,0,0,.5);
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.nuev-container.show {
  display: block;
}

.nuev-container .nuev-content {
  width: 70%;
  max-height: 80vh;
  background: #fff;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 40px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.nuev-container .nuev-content p {
  display: block;
  margin-left: 0;
}

.nuev-container .nuev-content .info-box {
  margin: 0 0 40px 0;
  overflow: auto;
}

.nuev-container .nuev-content .embed-responsive {
  width: 90% !important;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .nuev-container .nuev-content .embed-responsive {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.nuev-container .nuev-content .nuev-button-group {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
}

.nuev-container .nuev-content .nuev-button-group button {
  font-family: "Source Sans Pro",sans-serif;
  color: #fff;
}

@media (max-width: 767px) {
  .nuev-container .nuev-content .nuev-button-group button {
    margin-bottom: 15px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .nuev-container .nuev-content {
    width: 95%;
    padding: 25px;
  }

  .nuev-container .nuev-content .headline {
    font-size: 18px;
  }

  .nuev-container .nuev-content .subheadline {
    font-size: 16px;
  }
}

.insurance-container {
  display: none;
  position: fixed;
  background: rgba(0,0,0,.5);
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.insurance-container.show {
  display: block;
}

.insurance-container .insurance-content {
  width: 70%;
  max-height: 80vh;
  background: #fff;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 40px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.insurance-container .insurance-content p {
  display: block;
  margin-left: 0;
}

.insurance-container .insurance-content .info-box {
  margin: 0 0 40px 0;
  overflow: auto;
}

.insurance-container .insurance-content .embed-responsive {
  width: 90% !important;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .insurance-container .insurance-content .embed-responsive {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.insurance-container .insurance-content .insurance-button-group {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
}

.insurance-container .insurance-content .insurance-button-group button {
  font-family: "Source Sans Pro",sans-serif;
  color: #fff;
}

@media (max-width: 767px) {
  .insurance-container .insurance-content .insurance-button-group button {
    margin-bottom: 15px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .insurance-container .insurance-content {
    width: 95%;
    padding: 25px;
  }

  .insurance-container .insurance-content .headline {
    font-size: 18px;
  }

  .insurance-container .insurance-content .subheadline {
    font-size: 16px;
  }
}

.aimeos.checkout-confirm {
  margin: 50px 0;
}

.aimeos.checkout-confirm svg {
  width: 100%;
  margin: 0 0 30px 0;
}

.aimeos.checkout-confirm .confirmation-intro {
  margin-bottom: 30px;
}

.aimeos.checkout-confirm .btn {
  margin-top: 15px;
  font-size: 20px;
  margin-bottom: 25px;
}

.aimeos.checkout-confirm .btn-primary {
  color: #fff;
}

.aimeos.checkout-confirm .checkout-confirm-retry {
  margin-top: 40px;
}

.basket-standard>h1 {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 400;
  text-transform: capitalize;
}

.basket-standard .common-summary-detail .basket {
  margin: 30px 0 0 0;
}

.basket-standard .basket-standard-coupon .coupon-new input {
  margin: auto;
  border: 1px solid #581a27;
}

.basket-standard .button-group {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .basket-standard .button-group .btn-default {
    width: 100%;
  }
}

.basket-standard .button-group .btn-primary {
  color: #fff;
}

.basket-standard .button-group .btn-primary:hover,
.basket-standard .button-group .btn-primary:focus,
.basket-standard .button-group .btn-primary:active {
  color: #fff;
}

@media (max-width: 767px) {
  .basket-standard .button-group .btn-primary {
    width: 100%;
  }
}

.basket-standard .button-layer a:nth-child(2) {
  pointer-events: none;
}

.basket-standard .button-layer a:nth-child(2) div {
  display: none;
}

.basket-standard .steps {
  margin: 20px 0 0;
  font-weight: 400;
  text-align: center;
  counter-reset: step;
  padding: 0 0 3em 0;
}

.basket-standard .steps .step {
  border-color: #f0eff0;
  display: inline-block;
  overflow: hidden;
  width: 19%;
  color: #d3dbde;
  border-bottom: solid 8px #d3dbde;
  border-bottom-color: #d3dbde;
  vertical-align: baseline;
}

.basket-standard .steps .step.active {
  border-color: #d2d2d2;
}

.basket-standard .steps .step.active:after {
  content: none;
}

.basket-standard .steps .step.current {
  border-color: #3b3b3b;
}

@media (max-width: 767px) {
  .basket-standard .steps .step {
    width: 32%;
  }
}

.basket .info-small {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.basket .table .table-head {
  background: #f0eff0;
  padding: 10px 0;
  font-weight: 700;
}

.basket .table .table-head .th {
  text-align: center;
}

.basket .table .table-head .th.unitprice {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .basket .table .table-head .th.quantity {
    display: none;
  }
}

@media (max-width: 767px) {
  .basket .table .table-head .th {
    text-align: left;
  }
}

.basket .table .table-head .th .info {
  height: 30px;
  width: 30px;
}

.basket .table .table-head .th .info .info--text {
  top: 30px;
}

.basket .table .table-body {
  background: #fff;
}

.basket .table .table-body .product {
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid #f0eff0;
  position: relative;
  font-weight: 200;
}

.basket .table .table-body .product .delete {
  height: 27px;
  width: 27px;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  margin-left: auto;
  position: absolute;
}

@media (max-width: 767px) {
  .basket .table .table-body .product .delete {
    right: 0;
  }
}

.basket .table .table-body .product.total {
  font-weight: 700;
  font-size: 20px;
}

.basket .table .table-body .product:last-child {
  border-bottom: 0;
}

@media (max-width: 767px) {
  .basket .table .table-body .product .unitprice {
    font-size: 17px;
    font-weight: 700;
  }
}

.basket .table .table-body .product .details-row {
  align-items: center;
}

.basket .table .table-body .product .details-row .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.basket .table .table-body .product .details-row .image img {
  max-height: 5em;
  width: auto;
  max-width: initial;
}

@media (max-width: 767px) {
  .basket .table .table-body .product .details-row .image img {
    max-height: 100px;
    width: auto;
  }
}

.basket .table .table-body .product .quantity {
  font-size: 14px;
}

.basket .table .table-body .product .quantity input {
  max-width: 50px;
  margin: 0 10px;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
}

@media (max-width: 767px) {
  .basket .table .table-body .product .quantity {
    margin: 15px 0;
  }
}

@media (max-width: 767px) {
  .basket .table .table-body .product {
    padding-right: 0;
    padding-left: 0;
  }
}

.basket .table .table-foot {
  background-color: #f0eff0;
  padding: 15px 0;
  font-size: 20px;
  font-weight: 700;
}

.basket .table .table-foot .total {
  display: flex;
  align-items: center;
}

@media (min-width: 48em) {
  .basket .table .table-foot .total .title {
    padding-left: 30px;
  }
}

.basket .table .table-foot .leasing_net {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.basket .table .table-foot .leasing_net .info {
  height: 30px;
  width: 30px;
}

.basket .table .table-foot .price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.basket .table .table-foot .calculator--icon {
  margin-right: 5px;
  margin-left: -35px;
}

.basket .table .table-additional {
  display: flex;
  justify-content: flex-end;
}

.basket .table .table-additional .calculator-saving-layer {
  margin-top: 5px;
}

.basket .table .table-additional .calculator-saving-layer .button-layer {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .basket .table .table-additional .calculator-saving-layer {
    font-size: 14px;
  }
}

.basket .table .quantity,
.basket .table .unitprice,
.basket .table .price {
  text-align: center;
}

.basket .package .header {
  background: #e3e3e3;
}

.basket .package .header h3 {
  padding-left: 15px;
}

.aimeos-container .checkout-steps {
  display: none;
}

.faq-content .eleasageht {
  max-width: 50%;
}

.faq .faq-header .topic {
  display: inline-block;
  margin-right: 30px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.faq .faq-header .topic:last-child {
  margin-right: 0;
}

.faq .faq-body {
  margin-top: 15px;
}

.faq .faq-body .faqs {
  display: none;
}

.faq .faq-body .faqs.active {
  display: block;
}

.faq .faq-body .faqs .faq-item {
  margin-bottom: 15px;
}

.faq .faq-body .faqs .faq-item.active .question .arrow-down {
  display: none;
}

.faq .faq-body .faqs .faq-item.active .question .arrow-up {
  display: block;
}

.faq .faq-body .faqs .faq-item.active .answer {
  display: block;
}

.faq .faq-body .faqs .faq-item .question {
  cursor: pointer;
  position: relative;
  margin-right: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.faq .faq-body .faqs .faq-item .question .arrow-down,
.faq .faq-body .faqs .faq-item .question .arrow-up {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -20px;
  top: 0;
}

.faq .faq-body .faqs .faq-item .question .arrow-up {
  display: none;
}

.faq .faq-body .faqs .faq-item .answer {
  margin-top: 5px;
  display: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.faq .faq-body .faqs .faq-item .answer a {
  color: #802538;
}

.order-announcement {
  padding: 22px;
  margin-top: 10px;
  border: 1px solid #802538;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 767px) {
  .order-announcement {
    margin-top: 10px;
  }
}

.order-announcement .mpp-text {
  color: #802538;
  font-size: 18px;
}

.order-announcement .mpp-text .mppdate {
  padding: 0 3px;
  font-weight: 700;
}

.a-left {
  width: 35px;
  height: 35px;
  background-color: #050505;
  border-radius: 50%;
  opacity: .7;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  left: 1em;
  transition: .3s opacity ease-in;
}

.a-left:hover {
  opacity: 1;
  cursor: pointer;
}

.a-left:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
}

@media (max-width: 767px) {
  .a-left {
    width: 25px;
    height: 25px;
  }

  .a-left:hover {
    opacity: 1;
  }

  .a-left:after {
    width: 25px;
    height: 25px;
  }
}

.a-right {
  width: 35px;
  height: 35px;
  background-color: #050505;
  border-radius: 50%;
  opacity: .7;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  transition: .3s opacity ease-in;
}

.a-right:hover {
  opacity: 1;
  cursor: pointer;
}

.a-right:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
}

@media (max-width: 767px) {
  .a-right {
    width: 25px;
    height: 25px;
  }

  .a-right:hover {
    opacity: 1;
  }

  .a-right:after {
    width: 25px;
    height: 25px;
  }
}

.slider {
  width: 100%;
}

.slider #homepage-slider {
  max-width: 100%;
}

.slider #homepage-slider img {
  max-width: 100%;
}

.slider #homepage-slider .slick-slide {
  width: 100%;
}

.slider #homepage-slider .slick-slide img {
  width: 100%;
}

@media (min-width: 48em) {
  .slider #homepage-slider {
    margin-top: -30px;
  }
}

#homepage-slider-mobile {
  max-width: 100%;
}

#homepage-slider-mobile img {
  width: 100%;
}

.tile {
  border: 1px solid #802538;
  padding: 17px 20px 20px 20px;
  height: 100%;
}

.tile img {
  text-align: right;
}

.tile .tiles-header h3 {
  letter-spacing: -0.71px;
  line-height: 28px;
  font-weight: 700;
  text-transform: none;
  font-size: 24px;
}

.tile .icon {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin: 0;
}

.tile .tiles-text {
  min-height: 63px;
  font-size: 18px;
}

@media (max-width: 767px) {
  .tile .tiles-text {
    margin-top: 15px;
  }
}

.tile .tiles-button {
  background-color: #802538;
  color: #fff;
  border: none;
  border-radius: 30px;
  height: 29px;
  padding: 2px 35px 27px 35px;
  font-weight: 200;
  text-align: center;
}

@media (max-width: 767px) {
  .tile .tiles-button {
    white-space: nowrap;
    width: 100%;
    display: block;
    height: auto;
    padding: 5px 25px;
    bottom: 0;
  }
}

.tile .img-holder {
  text-align: right;
}

@media (max-width: 767px) {
  .tile {
    padding-bottom: 20px;
  }
}

.three-tiles {
  margin-top: 60px;
  margin-bottom: 100px;
  width: 100%;
}

@media (max-width: 767px) {
  .three-tiles {
    margin-top: 13px;
    margin-bottom: 40px;
  }
}

.service-tile {
  background-color: #802538;
  margin-top: 60px;
  padding: 22px;
}

.service-tile a:hover {
  color: #802538;
}

.service-tile h4,
.service-tile p {
  color: #fff;
}

.service-tile .service-button {
  background-color: #fff;
  border: 0;
  border-radius: 30px;
  height: 24px;
  padding: 0 30px 27px 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.top-products {
  position: relative;
  width: 100%;
}

.top-products h2.headline {
  text-transform: uppercase !important;
  color: #802538;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  left: 50%;
  font-weight: 400;
  transform: translateX(-50%);
  top: 0;
  padding: 0 16px;
}

@media (max-width: 767px) {
  .top-products h2.headline {
    top: -36px;
  }
}

.top-products hr {
  border-color: #802538;
}

.brand-slider {
  margin-top: 100px;
  position: relative;
  width: 100%;
}

@media (max-width: 767px) {
  .brand-slider {
    margin-top: 60px;
  }
}

.brand-slider .headline-wrapper {
  width: 100%;
  height: 8vh;
  position: relative;
}

.brand-slider .headline-wrapper h2.headline {
  text-transform: uppercase !important;
  color: #802538;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -19px;
  padding: 0 16px;
}

@media (max-width: 767px) {
  .brand-slider .headline-wrapper h2.headline {
    top: -50%;
    text-align: center;
  }
}

.brand-slider .headline-wrapper hr {
  border-color: #802538;
}

.brand-slider .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-slider .slick-track .slick-slide .item {
  justify-content: center;
  align-items: center;
  height: 100%;
}

.brand-slider .slick-track .slick-slide .item img {
  max-width: 50%;
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .tile-wrapper {
    margin-bottom: 10px;
  }
}

.initializing {
  display: none !important;
}

.service.contact .address-list {
  padding: 0;
  list-style: none;
}

.service {
  margin: 50px 0;
}

.service .btn {
  width: 15.5em;
}

.service p {
  margin-bottom: 5px;
}

.service .info-box {
  margin: 0;
  height: 100%;
}

.service .info-box .button-row {
  position: absolute;
  bottom: 15px;
  margin: 0 -10px;
  width: calc(100% - 12px);
  display: flex;
  flex-wrap: wrap;
}

.service .info-box .button-row .col-6,
.service .info-box .button-row .col-lg-6 {
  padding: 0 10px;
}

.service .info-box .button-row .btn {
  flex: 0 0 50%;
  margin: 0 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  min-width: auto;
  text-overflow: ellipsis;
}

.service .info-box .info-box--body p {
  margin-bottom: 10px;
}

.service .service-box {
  height: 100%;
  padding: 0 0 20px 0;
}

.service .service-intro {
  margin-bottom: 40px;
}

.service .service-intro .service-intro--image {
  text-align: center;
}

.service .service-intro .service-intro--image svg {
  margin: 50px 0;
}

.service .service-form h3 {
  padding-bottom: 10px;
}

.service .service-form .upload-container {
  margin-top: 40px;
}

.service .service-form .upload-container .upload-btn {
  padding: 4px 30px;
  font-size: 17px;
  margin-top: 8px;
  margin-right: 20px;
  display: inline-block;
}

.service .service-form .upload-container .upload-btn:hover {
  cursor: pointer;
}

.service .service-form .upload-container .file-list {
  display: inline-block;
}

.service .service-form .upload-container .file-list .file-name {
  font-size: 17px;
  color: #3b3b3b;
  margin-right: 15px;
}

.service .service-form .upload-container .file-list .file-name .loeschen {
  width: 25px;
  height: 25px;
}

.service .service-form .upload-container .file-format-note {
  font-size: 12px;
  display: block;
}

.service .service-form .service-form-item {
  margin-top: 20px;
}

.service .service-form .service-form-item label {
  font-weight: 700;
}

.service .service-form .service-form-item select,
.service .service-form .service-form-item .form-control {
  margin-top: 0;
}

.service .service-form .service-form-item textarea {
  display: block;
  width: 100%;
  border: 1px solid #f0eff0;
  padding: 10px 15px;
  border-radius: 0;
  box-shadow: none;
  transition: ease all .3s;
}

.service .service-form .service-form-item textarea:hover,
.service .service-form .service-form-item textarea:focus,
.service .service-form .service-form-item textarea:active {
  border-color: #8d8d8d;
  box-shadow: none;
  outline: 0;
}

.service .service-form .common-summary-detail {
  padding: 15px 0;
  margin-bottom: 80px;
  margin-top: -10px;
}

.service .service-form .common-summary-detail .basket {
  margin: 0;
}

.service .service-form .common-summary-detail .basket .product-row {
  align-items: center;
  border-bottom: 1px solid #f0eff0;
  padding-bottom: 15px;
}

.service .service-form .common-summary-detail .basket .product-row p {
  margin-bottom: 0;
}

.service .service-form .common-summary-detail .basket .product-row img {
  float: left;
  max-width: 6em;
  max-height: 8em;
  overflow: hidden;
  margin-right: 5%;
}

.service .service-form .common-summary-detail .basket .product-row .details-product {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .service .service-form .common-summary-detail .basket .product-row img {
    width: 100%;
    max-height: 100%;
  }
}

.service .service-form .service-description {
  margin-bottom: 80px;
}

.service .service-form .service-contact .contact-item {
  display: block;
}

.service .service-additonal {
  margin-top: 50px;
}

.service .service-additonal .info-container {
  margin-bottom: 20px;
}

.service .service-additonal .info-box ul {
  padding-left: 20px;
}

.service .service-additonal .btn-primary,
.service .service-additonal .btn-primary--bordered {
  width: 100%;
}

.service .service-additonal .btn-footer {
  width: 15.5em;
}

.service .service-additonal .note {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .service .service-additonal .btn {
    width: 100%;
    margin: 5px 0;
    text-align: center !important;
  }
}

.service.home .btn-primary,
.service.home .btn-primary--bordered {
  width: auto;
  min-width: 15em;
  margin: 25px 25px 0 0;
}

@media (max-width: 767px) {
  .service.home .btn-primary,
  .service.home .btn-primary--bordered {
    min-width: 100%;
    margin-right: 0;
  }
}

.service.service-summary h3 {
  border-bottom: 1px solid #f0eff0;
}

.service .ueberblick {
  margin-right: 5px;
}

.service .note {
  margin-top: 100px;
}

.service .service-subheadline {
  margin-top: 20px;
}

@media (min-width: 48em) {
  .service .service-subheadline {
    margin-top: 100px;
    margin-bottom: 30px;
  }
}

.service-intro--content.service-summary h1 {
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: none;
}

@media (min-width: 48em) {
  .service-intro--content h1 {
    margin-bottom: 50px;
  }
}

.service-form {
  margin-bottom: 60px;
}

.service-form input {
  border: 1px solid #d5d5d5;
  width: 100%;
  padding: 0 5px;
}

.service-form input[type=radio] {
  width: 15px;
}

.service-form label {
  font-weight: 700;
}

.service-form .customer-service-items {
  margin-bottom: 55px;
  font-size: 17px;
}

.customer-service-item {
  display: block;
  padding-bottom: 3px;
}

.service-group {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .service-group {
    display: block;
  }
}

.service-button-group {
  display: inline-block;
}

.service-button-group.forward {
  display: flex;
}

@media (max-width: 767px) {
  .service-button-group.forward {
    display: block;
  }
}

.service-summary-delivery .item-address {
  position: relative;
}

.service-summary-delivery .item-address input {
  width: 100%;
  padding: 0 5px;
}

.service-summary-delivery .item-address input[type=radio] {
  width: 15px;
  position: absolute;
  top: 25px;
  left: 0;
}

.aimeos.account-profile .item-address {
  height: 100%;
  border: 1px solid #d5d5d5;
}

.aimeos.account-profile .item-address .header {
  height: 100%;
}

.aimeos.account-profile .item-address .header h3 {
  font-weight: 400;
}

.aimeos.account-profile .item-address .header .modify {
  background-color: #802538;
  color: #fff;
  border-radius: 15px;
  padding: 3px 20px;
}

.aimeos.account-profile .item-address .customer-info-item {
  font-size: 17px;
}

.aimeos.account-profile .item-address .header,
.aimeos.account-profile .item-address .content {
  background: rgba(0,0,0,0);
  padding: 20px 60px;
  margin: 0;
}

.calculator .highligh-border {
  border: 1px solid #802538;
  background-color: #f0eff0;
}

.calculator .row {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.calculator .row.mobile-info-container {
  margin: 0;
}

.calculator .row .image {
  display: flex;
  align-items: center;
}

.calculator .row .image img {
  max-width: 100%;
}

.calculator .row .col-sm-4 {
  text-align: right;
}

.calculator .row .col-sm-4:first-child {
  text-align: left;
}

.calculator label {
  font-weight: 700;
  max-width: 85%;
}

.calculator label.checkbox-label {
  width: 305px;
  position: relative;
}

@media (max-width: 767px) {
  .calculator label.checkbox-label .checkbox {
    right: -15%;
  }
}

.calculator select,
.calculator input,
.calculator fieldset label {
  margin-top: 0;
}

.calculator fieldset label {
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  margin-right: 50px;
}

.calculator fieldset input+label {
  margin-right: 50px;
  margin-left: 5px;
}

.calculator .form-field,
.calculator .select-value {
  margin-bottom: 25px;
}

.calculator .striped {
  padding: 10px 0;
  background: #f0eff0;
  font-size: 20px;
}

.calculator .state-container {
  display: block;
}

.calculator .sized {
  font-size: 20px;
}

@media (max-width: 767px) {
  .calculator .sized {
    font-size: 18px;
  }
}

.calculator .bold {
  font-weight: 700;
}

.calculator .calculate {
  cursor: pointer;
  padding: 10px 25px;
  font-size: 21px;
  line-height: 27px;
  font-weight: 200;
  display: block;
  width: 50%;
  max-width: 400px;
  margin: 30px 0 40px 0;
  text-align: center;
}

@media (max-width: 767px) {
  .calculator .calculate {
    width: 100%;
  }
}

.calculator .processing .checkbox {
  right: 0;
}

.calculator .info {
  float: right;
}

.calculator .info .icon {
  display: inline-block;
  float: right;
  width: 30px;
  height: 30px;
}

@media (max-width: 767px) {
  .calculator .info {
    position: relative;
  }
}

.calculator-summary {
  font-size: 17px;
}

.calculator-summary .calculation-information {
  margin-top: 40px;
}

.calculator-summary .calculation-information h2 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .calculator-summary .calculation-information h2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .calculator-summary .calculation-information .row .col-12 {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .calculator-summary .calculation-information .row:not(.striped).bold {
    font-weight: 400;
    font-size: 15px;
  }
}

.calculator-summary .summary {
  margin: 60px 0 30px 0;
}

@media (max-width: 767px) {
  .calculator-summary .summary .row .col-6,
  .calculator-summary .summary .row .col-12 {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .calculator-summary .summary .row .col-12 {
    margin-bottom: 8px;
  }

  .calculator-summary .summary .row .col-12+.col-12 {
    margin-bottom: 0;
  }
}

.calculator-summary hr.mobile {
  display: none;
}

@media (max-width: 767px) {
  .calculator-summary hr.mobile {
    display: block;
  }
}

@media (max-width: 767px) {
  .calculator-summary .striking {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .calculator-summary {
    font-size: 16px;
  }
}

.all-around-protection .protection-head {
  padding: 45px 0;
  font-size: 17px;
}

.all-around-protection .protection-head .headline-text {
  margin-bottom: 6px;
  display: inline-block;
}

.all-around-protection .protection-head .protection-headlines {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.all-around-protection .protection-head .protection-headlines .protection-text {
  font-size: 21px;
  align-self: self-end;
}

.all-around-protection .protection-head .icon {
  width: 160px;
  height: 160px;
}

.all-around-protection .protection-head .icon.download {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.all-around-protection .protection-head .download-link {
  font-size: 17px;
  margin-top: 25px;
}

.all-around-protection .protection-head .headlines {
  padding-top: 15px;
}

.all-around-protection .protection-head .headlines h1 {
  margin-bottom: 20px;
  font-weight: 400;
}

.all-around-protection .service-portal {
  padding: 15px;
  font-size: 24px;
  background-color: #802538;
  font-family: "Bradford Medium",sans-serif;
  color: #fff;
  min-height: 100px;
}

.all-around-protection .service-portal .img-holder {
  display: flex;
  align-items: center;
}

.all-around-protection .service-portal .img-holder svg {
  stroke: #fff;
  fill: none;
  max-height: 70px;
}

.all-around-protection .service-portal .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 0px)and (max-width: 767px) {
  .all-around-protection .service-portal .content {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
  }
}

.all-around-protection .service-portal .content p {
  margin: 0;
}

.all-around-protection .service-portal .portal-button {
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  color: #802538;
  font-size: 21px;
  padding: .1rem 1.2rem;
  margin-right: 2rem;
  text-align: center;
}

@media (max-width: 767px) {
  .all-around-protection .service-portal .portal-button {
    padding: .1rem .6rem;
  }
}

.all-around-protection .card {
  border: 1px solid #4bb543;
  width: 100%;
}

.all-around-protection .card .card-header {
  background-color: #4bb543;
}

.all-around-protection .card .card-header h4 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 200;
  margin-bottom: 0;
}

.all-around-protection .benefit {
  font-weight: 700;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.all-around-protection .benefit:before {
  content: "";
  width: 40px;
  height: 40px;
  display: inline-block;
}

.aimeos.account-profile .profile-header {
  margin-bottom: 30px;
}

.aimeos.account-profile .profile-into {
  margin-bottom: 60px;
}

.aimeos.account-profile .profile-edit-data {
  margin-bottom: 56px;
}

.aimeos.account-profile .account--data__item .form-group .minibutton span {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.aimeos.account-profile .account--data__item .info-box--header h3 {
  margin-top: 0;
}

.aimeos.account-profile .account--data__item label {
  font-weight: 700;
  margin: 0;
}

.aimeos.account-profile .account--data__item button {
  width: 16em;
}

.aimeos.account-profile .account--data__item .note {
  margin: 60px 0;
}

.aimeos.account-profile .profile-value {
  margin-bottom: 4px;
  display: block;
}

.aimeos.account-profile .account-profile-personal-data .error-list {
  list-style: none;
}

.aimeos.account-profile .account-profile-personal-data .personal-data {
  margin: 30px 0;
}

.aimeos.account-profile .account-profile-personal-data .personal-data .name,
.aimeos.account-profile .account-profile-personal-data .personal-data .value {
  display: inline-block;
}

.aimeos.account-profile .account-profile-personal-data .personal-data .name {
  width: 210px;
}

.aimeos.account-profile .account-profile-personal-data form label {
  margin-top: 25px;
  margin-bottom: 0;
  font-weight: 700;
  width: 100%;
  height: 25px;
}

.aimeos.account-profile .account-profile-personal-data form .form-item.error label {
  color: #f30;
}

.aimeos.account-profile .account-profile-personal-data form .form-item.error input {
  background-color: #fee;
  border: solid 1px #f30;
}

.aimeos.account-profile .account-profile-personal-data form .form-item input {
  margin-top: 5px;
}

.aimeos.account-profile .account-profile-personal-data form .btn-primary {
  width: 30%;
  padding: 10px 25px;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .aimeos.account-profile .account-profile-personal-data form .btn-primary {
    width: 100%;
  }
}

.aimeos.account-profile .account-profile-personal-data .note {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .aimeos.account-profile .account--data__item {
    margin-bottom: 30px;
  }
}

.aimeos.account-profile .customer-info-item {
  display: block;
  margin-bottom: 5px;
}

.aimeos.account-profile .order-history-tile {
  margin-bottom: 30px;
}

.aimeos.account-profile .order-history-tile .account-data-content {
  padding-bottom: 20px;
}

.aimeos.account-profile .header-and-icon {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.aimeos.account-profile .header-and-icon .icon.package {
  width: 60px;
  height: 60px;
}

.aimeos.account-profile .header-and-icon h3 {
  margin: 0;
  font-size: 34px;
}

.aimeos.account-profile .contact-headline {
  margin-top: 60px;
}

.item-address-form h3 {
  margin-top: 80px;
  font-weight: 400;
  text-transform: uppercase;
}

.delivery-address-headline-text {
  margin-bottom: 60px;
}

.item-updateable button {
  background: rgba(0,0,0,0);
  border: 0 none;
}

.btn-update {
  padding: 2px 40px;
}

.btn-update:hover {
  cursor: pointer;
}

.account-profile-budget-view {
  margin: 80px 0;
}

.account-profile-budget-view.disabled .budget-view-header,
.account-profile-budget-view.disabled .budget-view-progress,
.account-profile-budget-view.disabled .budget-view-progress-values {
  display: none;
}

.account-profile-budget-view h2 {
  font-weight: 400;
}

.account-profile-budget-view .budget-view-header {
  background: rgba(0,0,0,0);
  border: 1px solid #d5d5d5;
  margin-bottom: 10px;
  padding: 8px 15px;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
}

.account-profile-budget-view .budget-view-progress .progress-bar {
  width: 100%;
  position: relative;
  background: #89c882;
  height: 10px;
  margin-bottom: 10px;
}

.account-profile-budget-view .budget-view-progress .progress-bar span.progress {
  position: absolute;
  background: #a10000;
  top: 0;
  bottom: 0;
  left: 0;
  height: auto;
  max-width: 100%;
  border-radius: 0;
}

.account-profile-budget-view .budget-view-progress-values .progress-values {
  font-size: 19px;
  font-weight: 700;
}

.account-profile-budget-view .budget-view-progress-values .progress-values.remaining {
  color: #89c882;
  text-align: right;
}

.account-profile-budget-view .budget-view-progress-values .progress-values.used {
  color: #a10000;
  text-align: left;
}

.account-history-list .garantie {
  height: 45px;
  width: 45px;
}

.account-history-list .history-list {
  margin-top: 40px;
}

.account-history-list .history-list .history-item {
  border: 0 none;
  margin: 1rem 0;
  display: block;
}

.account-history-list .history-list .history-item .history-header {
  position: relative;
  display: block;
  width: 100%;
  background: #802538;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  padding: 15px;
  font-weight: 200;
  font-size: 23px;
  line-height: 21px;
  border: 1px solid #802538;
  cursor: pointer;
}

.account-history-list .history-list .history-item .history-header .show-details {
  vertical-align: middle;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 15px;
  padding: 8px 30px;
  margin: 10px 0;
  line-height: 1;
  background: #fff;
  top: 0;
  background: #fff;
}

.account-history-list .history-list .history-item .history-header .show-details * {
  cursor: pointer;
}

.account-history-list .history-list .history-item .history-header .show-details .show-details--text {
  color: #802538;
  display: block;
  font-size: 1rem;
  text-transform: none;
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .history-header .show-details .show-details--text {
    display: none;
  }
}

.account-history-list .history-list .history-item .history-header:hover {
  color: #fff;
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .history-header {
    font-size: 18px;
  }
}

.account-history-list .history-list .history-item .history-body {
  border-top: 0;
  padding: 30px 0;
}

.account-history-list .history-list .history-item .history-body>.row {
  margin-left: 0;
  margin-right: 0;
}

.account-history-list .history-list .history-item .history-body .attr-item .name {
  font-weight: 700;
}

.account-history-list .history-list .history-item .basket {
  width: 100%;
  margin: 0;
}

.account-history-list .history-list .history-item .basket a {
  color: #802538;
}

.account-history-list .history-list .history-item .basket .products {
  border-bottom: 1px solid #802538;
  margin-bottom: 60px;
}

.account-history-list .history-list .history-item .basket .product {
  position: relative;
  padding: 0;
  margin: 0;
}

.account-history-list .history-list .history-item .basket .product p {
  margin-bottom: 0;
}

.account-history-list .history-list .history-item .basket .product .product-row {
  padding: 20px 0;
  border: 1px solid #802538;
  border-bottom: 0;
  margin: 0;
  align-items: center;
}

.account-history-list .history-list .history-item .basket .product.disabled .details {
  opacity: .7;
}

.account-history-list .history-list .history-item .basket .product .details.details-product {
  display: flex;
  align-items: center;
}

.account-history-list .history-list .history-item .basket .product .details.details-product img {
  float: left;
  max-width: 6em;
  max-height: 8em;
  overflow: hidden;
  margin-right: 5%;
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .basket .product .details.details-product img {
    width: 100%;
    max-height: 100%;
    max-width: 7em;
    float: none;
    margin: 0 auto;
    display: block;
  }
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .basket .product .details {
    margin: 20px 0;
  }
}

.account-history-list .history-list .history-item .common-summary-detail {
  margin-top: 40px;
}

.account-history-list .history-list .history-item .common-summary-detail .header {
  padding: 10px 0;
  margin: 0;
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .common-summary-detail {
    margin-top: 25px;
  }
}

.account-history-list .history-list .history-item .common-summary-address .contract-data span {
  display: block;
}

.account-history-list .history-list .history-item .common-summary-address .contract-data .user-licensing-agreement {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .common-summary-address .contract-data {
    margin-bottom: 20px;
  }
}

.account-history-list .history-list .history-item .common-summary-address .order-question {
  display: flex;
  margin: 30px 0 0 0;
  align-items: center;
}

.account-history-list .history-list .history-item .common-summary-address .order-question a {
  font-weight: 400 !important;
  color: #fff !important;
}

.account-history-list .history-list .history-item .common-summary-address .order-question a:hover,
.account-history-list .history-list .history-item .common-summary-address .order-question a:focus,
.account-history-list .history-list .history-item .common-summary-address .order-question a:active {
  color: #fff !important;
}

@media (max-width: 767px) {
  .account-history-list .history-list .history-item .common-summary-address .order-question {
    font-size: 15px;
    margin-top: 20px;
  }
}

.account-history-list .history-list .history-item .common-summary-address .order-tracking {
  margin-top: 25px;
}

.account-history-list .history-list .history-item .common-summary-address .order-tracking .order-tracking-number {
  font-weight: 700;
  color: #802538;
  font-size: 18px;
}

.account-history-list .history-list .history-item .common-summary-address .contract-data .header,
.account-history-list .history-list .history-item .common-summary-address .order-tracking .header,
.account-history-list .history-list .history-item .common-summary-address .payment .header {
  padding: 0 0 10px 0;
  margin: 0;
  border-bottom: 1px solid #f0eff0;
}

.account-history-list .history-list .history-item .common-summary-address .contract-data .content,
.account-history-list .history-list .history-item .common-summary-address .order-tracking .content,
.account-history-list .history-list .history-item .common-summary-address .payment .content {
  padding: 15px 0;
  margin: 0;
}

.account-history-list .history-list .history-item .common-summary-address .contract-data .content a,
.account-history-list .history-list .history-item .common-summary-address .order-tracking .content a,
.account-history-list .history-list .history-item .common-summary-address .payment .content a {
  color: #802538;
  font-weight: 700;
}

.account-history-list .order-report-button {
  display: flex;
  justify-content: center;
}

.account-history-list .order-report-button:before {
  content: "‹";
  color: #ffb729;
  transform: rotate(270deg);
  display: inline-block;
  font-size: 50px;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .account-history-list .order-report-button {
    margin: 15px 0;
  }
}

.account-history-list .order-reports {
  margin-top: 35px;
  margin-bottom: 5px;
  text-align: center;
  display: none;
  border-top: 1px dotted #d5d5d5;
  padding: 10px 0;
}

.account-history-list .order-reports .order-report {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.account-history-list .order-reports .order-report .transport {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.account-history-list .order-reports .order-report .theft {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.account-history-list .order-reports .order-report .damage {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .account-history-list .order-reports .order-report {
    justify-content: flex-start;
    font-size: 13px;
    margin: 10px 0;
  }
}

@media (max-width: 767px) {
  .account-history-list .order-reports {
    margin-top: 30px;
  }
}

.account-history-list .opened {
  display: block;
}

.account-history-list .ueberblick {
  margin-right: 10px;
}

.aimeos .bu-account-history-order .header {
  padding: 0 0 10px 0;
}

.aimeos .bu-account-history-order .header h3 {
  margin: 0;
}

.aimeos .bu-account-history-order .common-summary-detail .header-underline {
  border-bottom: 1px solid #3b3b3b !important;
  margin-bottom: 10px;
}

.aimeos .bu-account-history-order .common-summary .header {
  border-bottom: 1px solid #3b3b3b !important;
  padding: 0 0 10px 0;
}

.aimeos .bu-account-history-order .basket .package .header {
  border: 0 none;
}

.aimeos .account-history-list .history-list .history-item .common-summary-address .contract-data .header {
  border-bottom: 1px solid #3b3b3b !important;
}

.common-summary-address .contract-data {
  padding-top: 49px;
}

.common-summary-delivery .item.contact .content,
.common-summary-delivery .item.payment .content {
  padding: 15px 0;
  margin: 0;
}

.common-summary-delivery .item.contact .header,
.common-summary-delivery .item.payment .header {
  border-bottom: 1px solid #3b3b3b;
  margin: 0;
}

.common-summary-detail .basket .order-tracking .content {
  margin: 0;
  padding: 0 0 10px 0;
}

.common-summary-detail .basket .order-tracking .row {
  padding-top: 5px;
}

.deliverynumber {
  background-color: #e3e3e3;
  padding: 10px 12px;
  margin-bottom: 10px;
}

.account-profile-address {
  margin-bottom: 30px;
}

.service-summary-delivery .item-address {
  height: 100%;
  border: 0 none;
}

.service-summary-delivery .item-address .header {
  height: 100%;
}

.service-summary-delivery .item-address .header h3 {
  font-weight: 400;
}

.service-summary-delivery .item-address .header .modify {
  background-color: #802538;
  color: #fff;
  border-radius: 15px;
  padding: 3px 20px;
}

.service-summary-delivery .item-address .customer-info-item {
  font-size: 17px;
}

.service-summary-delivery .item-address .header,
.service-summary-delivery .item-address .content {
  background: rgba(0,0,0,0);
  padding: 20px 60px;
  margin: 0;
}

.service-summary-delivery #ca_mpp_deliveryoption-null {
  margin-right: 25px;
}

.service-summary-delivery .account--data__item .btn {
  border: 0 none;
  padding: 5px 20px 30px 0;
  font-size: 17px;
}

.button-group {
  display: flex;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

#header-and-body {
  background-color: #ececed;
}

body {
  background-color: #802538;
}

.body {
  padding: 25px 0 25px 0;
}

a[href^="tel:"] {
  color: #000;
}

@media (max-width: 767px) {
  a[href^="tel:"] {
    text-decoration: underline;
  }
}

@media (min-width: 48em) {
  a[href^="tel:"] {
    text-decoration: none;
    pointer-events: none;
  }
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.main-product-marker {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 50%;
  display: inline-block;
}

.main-product-marker:before {
  content: "A";
  text-align: center;
  width: 30px;
  height: 30px;
  display: block;
  font-size: 22px;
  line-height: 32px;
}

@font-face {
  font-family: "Bradford Sub Medium";
  src: url("../fonts/bradford/BradfordLLSub-Medium.eot");
  src: url("../fonts/bradford/BradfordLLSub-Medium.woff") format("woff"),url("../fonts/bradford/BradfordLLSub-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Bradford Medium";
  src: url("../fonts/bradford/BradfordLLWeb-Medium.eot");
  src: url("../fonts/bradford/BradfordLLWeb-Medium.woff") format("woff"),url("../fonts/bradford/BradfordLLWeb-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Bradford Sub Medium Italic";
  src: url("../fonts/bradford/BradfordLLSub-MediumItalic.eot");
  src: url("../fonts/bradford/BradfordLLSub-MediumItalic.woff") format("woff"),url("../fonts/bradford/BradfordLLSub-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Bradford Medium Italic";
  src: url("../fonts/bradford/BradfordLLWeb-MediumItalic.eot");
  src: url("../fonts/bradford/BradfordLLWeb-MediumItalic.woff") format("woff"),url("../fonts/bradford/BradfordLLWeb-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Neuzeit Bold";
  src: url("../fonts/neuzeit/38B08A_0_0.eot");
  src: url("../fonts/neuzeit/38B08A_0_0.eot") format("embedded-opentype"),url("../fonts/neuzeit/38B08A_0_0.woff2") format("woff2"),url("../fonts/neuzeit/38B08A_0_0.woff") format("woff"),url("../fonts/neuzeit/38B08A_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "Neuzeit Regular";
  src: url("../fonts/neuzeit/38B08A_1_0.eot");
  src: url("../fonts/neuzeit/38B08A_1_0.eot") format("embedded-opentype"),url("../fonts/neuzeit/38B08A_1_0.woff2") format("woff2"),url("../fonts/neuzeit/38B08A_1_0.woff") format("woff"),url("../fonts/neuzeit/38B08A_1_0.ttf") format("truetype");
}

h1,
h2,
h3 {
  font-family: "Bradford Medium",sans-serif;
}

a,
p,
span {
  font-family: "Neuzeit Regular",sans-serif;
}

h1,
.aimeos h1 {
  font-weight: inherit;
  text-transform: inherit;
}

h2,
.aimeos h2 {
  font-weight: inherit;
}

h3,
.aimeos h3 {
  text-transform: inherit;
  font-weight: inherit;
}

.eleasa-speech-bubble {
  border: 1px solid #000;
  margin: 0 30px;
  position: relative;
  border-radius: 15px;
}

.eleasa-speech-bubble.top:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid #000;
  border-right: 20px solid rgba(0,0,0,0);
  border-top: 20px solid rgba(0,0,0,0);
  border-bottom: 20px solid #000;
  left: 263px;
  top: -40px;
}

@media (max-width: 767px) {
  .eleasa-speech-bubble.top:before {
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid rgba(0,0,0,0);
    border-bottom: 20px solid #000;
    left: 50%;
    top: -40px;
  }
}

.eleasa-speech-bubble.top:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  left: 264px;
  top: -38px;
  border-left: 20px solid #fff;
  border-right: 20px solid rgba(0,0,0,0);
  border-top: 20px solid rgba(0,0,0,0);
  border-bottom: 20px solid #fff;
}

@media (max-width: 767px) {
  .eleasa-speech-bubble.top:after {
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid rgba(0,0,0,0);
    border-bottom: 20px solid #fff;
    left: 50%;
    top: -39px;
  }
}

.eleasa-speech-bubble.bottom:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid #000;
  border-right: 20px solid rgba(0,0,0,0);
  border-top: 20px solid #000;
  border-bottom: 20px solid rgba(0,0,0,0);
  left: 263px;
  bottom: -40px;
}

@media (max-width: 767px) {
  .eleasa-speech-bubble.bottom:before {
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid #000;
    border-bottom: 20px solid rgba(0,0,0,0);
    left: 50%;
    bottom: -40px;
  }
}

.eleasa-speech-bubble.bottom:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid #fff;
  border-right: 20px solid rgba(0,0,0,0);
  border-top: 20px solid #fff;
  border-bottom: 20px solid rgba(0,0,0,0);
  bottom: -38px;
  left: 264px;
}

@media (max-width: 767px) {
  .eleasa-speech-bubble.bottom:after {
    bottom: -39px;
    left: 50%;
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid #fff;
    border-bottom: 20px solid rgba(0,0,0,0);
  }
}

.eleasa-speech-bubble.left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid rgba(0,0,0,0);
  border-right: 20px solid #000;
  border-top: 20px solid rgba(0,0,0,0);
  border-bottom: 20px solid #000;
  top: 26%;
  left: -41px;
}

@media (max-width: 767px) {
  .eleasa-speech-bubble.left:before {
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid rgba(0,0,0,0);
    border-bottom: 20px solid #000;
    left: 50%;
    top: -40px;
  }
}

.eleasa-speech-bubble.left:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid rgba(0,0,0,0);
  border-right: 20px solid #fff;
  border-top: 20px solid rgba(0,0,0,0);
  border-bottom: 20px solid #fff;
  top: 25%;
  left: -39px;
}

@media (max-width: 767px) {
  .eleasa-speech-bubble.left:after {
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid rgba(0,0,0,0);
    border-bottom: 20px solid #fff;
    left: 50%;
    top: -39px;
  }
}

header {
  background-color: #fff;
}

header .catalog-filter-search .value {
  border-radius: 15px 0 0 15px;
  border-color: #000 !important;
}

header .catalog-filter-search .reset {
  border-color: #000 !important;
}

header .catalog-filter-search button:last-child {
  border-radius: 0 15px 15px 0;
  border-color: #000;
}

header .nav-budget {
  background-color: #c9425e;
  padding: 5px 25px;
  display: inline-block;
  float: right;
  margin: 15px 0 0;
  border-radius: 10px;
}

header .nav-budget:hover {
  background-color: #c9425e;
}

header .nav-budget span {
  text-transform: none;
  color: #fff;
}

@media (max-width: 767px) {
  header.header>.container {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  header.header .header--logo img {
    max-width: 85px;
  }
}

.footer {
  background-color: #802538;
}

.footer .fb-link-text {
  color: #fff;
}

.footer .footer-info {
  color: #fff;
}

.footer .footer-menu--item {
  padding-bottom: 0;
}

.footer .footer-menu--item a,
.footer .footer-menu--item .footer-info {
  color: #fff;
}

.footer h2 {
  color: #fff;
  text-transform: none;
  margin-bottom: 10px;
}

.footer .cooperation-banner {
  color: #fff;
}

.footer .cooperation-banner span {
  display: block;
  font-size: 25px;
}

.footer .cooperation-banner .eleasa {
  max-width: 250px;
}

.order-announcement {
  background-color: #802538;
}

.order-announcement .mpp-text {
  color: #fff;
}

@media (min-width: 48em) {
  .slider #homepage-slider {
    margin-top: 0;
  }
}

.home-img {
  margin: 0;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}

.home-img img {
  max-width: 100%;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul {
  text-align: center;
}

@media (max-width: 767px) {
  .main-navigation .catalog-filter .catalog-filter-tree>ul {
    margin-left: 0;
  }
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>a {
  display: inline-block;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul {
  padding: 10px 0;
  box-shadow: none;
  border: 0;
  background: #802538;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li {
  padding: 3px 15px;
  margin-bottom: 5px;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li a {
  padding: 0;
  color: #fff;
  text-transform: uppercase;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li:last-child {
  margin-bottom: 0;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li.active,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li:hover {
  background: #fff;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li.active a,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li>ul>li:hover a {
  color: #802538;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li svg.arrow-down {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: -15px;
  margin-top: 3px;
  fill: #fff;
  padding-right: 5px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .main-navigation .catalog-filter .catalog-filter-tree>ul>li svg.arrow-down {
    display: none;
  }
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li:last-child {
  margin-right: 0;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li.active,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li:hover {
  background: #fff;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li.active>a,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li:hover>a {
  color: #000;
}

.main-navigation .catalog-filter .catalog-filter-tree>ul>li.active svg.arrow-down,
.main-navigation .catalog-filter .catalog-filter-tree>ul>li:hover svg.arrow-down {
  fill: #802538;
}

.meta-navigaton--basket {
  line-height: 12px;
}

.meta-navigaton--basket span {
  font-family: "Neuzeit Bold",sans-serif;
}

.first-level {
  justify-content: center;
  padding: 0;
  text-align: center;
  flex-wrap: nowrap;
  align-items: center;
  line-height: 1.8;
  margin-bottom: 0;
  margin-block-end: 0;
}

.first-level .nav-item {
  margin: 0 1rem;
  display: inline-block;
}

@media (max-width: 767px) {
  .first-level .nav-item {
    width: 100%;
  }
}

.first-level .nav-item.active a {
  color: #000;
  background-color: #fff;
}

.first-level .nav-item a {
  display: inline-block;
  height: 45px;
  text-transform: uppercase;
  padding: 10px 14px;
  font-size: 16px;
  color: #fff;
}

.first-level .nav-item a:hover {
  color: #000;
  background-color: #fff;
}

.shop-navigation {
  background-color: #fff;
  display: none;
  min-height: 44px;
  z-index: 10;
  position: relative;
}

.shop-navigation.show {
  display: block;
}

.shop-navigation .catalog-filter-tree {
  text-align: center;
  margin: 0;
}

.shop-navigation .catalog-filter-tree>ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.shop-navigation .catalog-filter-tree>ul>li {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 200;
  display: block;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  position: relative;
}

.shop-navigation .catalog-filter-tree>ul>li a {
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.shop-navigation .catalog-filter-tree>ul>li>ul {
  display: none;
}

.shop-navigation .catalog-filter-tree>ul>li.active a {
  background-color: #ececec;
  color: #000;
  font-weight: bold;
}

.shop-navigation .catalog-filter-tree>ul>li:hover a {
  background-color: #ececec;
  color: #000;
}

.shop-navigation .catalog-filter-tree>ul>li:hover>ul {
  position: absolute;
  display: block;
  left: 0;
  transition: all .2s ease;
  background: #ececec;
  margin: 0;
  width: 100%;
  padding: 0;
}

.shop-navigation .catalog-filter-tree>ul>li:hover>ul>li a {
  color: #000;
  text-transform: none;
  font-weight: normal;
}

.shop-navigation .catalog-filter-tree>ul>li:hover>ul>li.active a,
.shop-navigation .catalog-filter-tree>ul>li:hover>ul>li:hover a {
  color: #000;
  font-weight: bold;
}

#store-location {
  background-color: #fff;
}

#store-location .store-headline {
  font-size: 39px;
  margin-bottom: 50px;
  margin-top: 85px;
}

.basket-standard {
  background: #fff;
  padding: 25px;
}

.basket .table .table-head,
.basket .table .table-foot {
  background: #f5f5f5;
}

.catalog-detail .catalog-detail--stage .catalog-stage-navigator {
  float: none;
  width: 100%;
}

.catalog-detail .catalog-detail--stage .catalog-stage-navigator nav {
  justify-content: flex-end;
}

.catalog-detail .catalog-detail-calculate-saving {
  margin-top: 25px;
}

.catalog-detail .catalog-detail-calculate-saving a {
  display: block;
  padding: 25px 25px 22px 25px;
  background: #802538;
  color: #fff !important;
  text-align: center;
  line-height: normal;
  font-size: 18px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-calculate-saving a {
    font-size: 16px;
    padding: 20px 25px 18px 25px;
  }
}

.catalog-detail .catalog-detail-additional {
  padding: 25px;
  background: #fff;
  margin-top: 25px;
}

.catalog-detail .catalog-detail-additional .header {
  border-radius: 10px;
  border: 1px solid #802538;
  display: inline-block;
  font-family: "Neuzeit Regular",sans-serif;
  font-size: 14px;
  color: #802538;
  line-height: 14px;
  padding: 10px 35px 7px 35px;
  margin: 0;
  text-transform: inherit;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}

.catalog-detail .catalog-detail-additional .header.opened,
.catalog-detail .catalog-detail-additional .header:hover {
  background: #802538;
  color: #fff;
}

@media (min-width: 64em) {
  .catalog-detail .catalog-detail-additional .header {
    margin-bottom: 0;
    width: auto;
  }
}

.catalog-detail .catalog-detail-additional .additional-box .content {
  margin: 50px 0 25px;
  padding: 0;
}

@media (min-width: 64em) {
  .catalog-detail .catalog-detail-additional .additional-box.description .item {
    padding: 0;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 80px;
    -webkit-column-gap: 80px;
    -moz-column-gap: 80px;
  }
}

.catalog-detail .catalog-detail-basket .stock-list {
  width: unset;
  padding: 5px 0;
}

.catalog-detail .catalog-detail-basket .stock-list .stockitem .stocktext {
  color: #3b3b3b !important;
  font-family: "Neuzeit Bold",sans-serif;
  margin: 0;
}

.catalog-detail .catalog-detail-basket .main-product {
  padding-right: 50px;
  display: inline-block;
}

.catalog-detail .catalog-detail-basket .main-product .info {
  margin-left: 0;
}

.catalog-detail .catalog-detail-basket .main-product .info .info-small {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-basket .main-product {
    margin-bottom: 10px;
  }
}

.catalog-detail .catalog-detail-basket .addbasket {
  margin: 0;
}

.catalog-detail .catalog-detail-basket .addbasket .input-group {
  display: flex;
  justify-content: flex-end;
}

.catalog-detail .catalog-detail-basket .addbasket .input-group button {
  width: 100%;
  border-radius: 15px;
  font-family: "Neuzeit Regular",sans-serif;
  font-size: 16px;
  line-height: normal;
  padding: 15px;
}

@media (min-width: 48em) {
  .catalog-detail .catalog-detail-basket .first {
    display: flex;
  }
}

.catalog-detail .catalog-detail-basic {
  background: #fff;
  padding: 25px;
  margin-bottom: 35px;
}

.catalog-detail .catalog-detail-basic+.catalog-detail-basic {
  margin-bottom: 25px;
}

.catalog-detail .catalog-detail-basic .short {
  margin-top: 15px;
}

.catalog-detail .catalog-detail-basic .product-description {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-basic .product-button {
    margin: 10px 0;
  }
}

.catalog-detail .catalog-detail-basic h1.name {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 25px;
  line-height: normal;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-basic h1.name {
    font-size: 20px;
  }
}

.catalog-detail .catalog-detail-basic .all-round-protection {
  margin-right: 30px;
  display: block;
  margin-bottom: 20px;
  margin-top: 10px;
}

.catalog-detail .catalog-detail-basic .all-round-protection a {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-basic .all-round-protection a {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-basic .all-round-protection {
    margin-bottom: 25px;
  }
}

.catalog-detail .product .info-small {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.catalog-detail .product .info-small .label {
  border-bottom: 1px solid;
  margin-left: 5px;
  line-height: normal;
}

.catalog-detail .product .price-list {
  margin-top: 25px;
  display: block;
  text-align: left;
}

.catalog-detail .product .price-list>.row {
  align-items: center;
}

.catalog-detail .product .price-list .catalog-detail-basket-attribute {
  display: none;
}

.catalog-detail .product .price-list .price-actual .price-text {
  line-height: normal;
}

.catalog-detail .product .price-list .price-actual .price-text .value {
  font-weight: normal;
  vertical-align: inherit;
  font-family: "Neuzeit Bold",sans-serif;
}

@media (max-width: 767px) {
  .catalog-detail .product .price-list .price-actual .price-text {
    text-align: left;
  }
}

.catalog-detail .product .price-list .price-actual .currency_star {
  font-size: 46px;
  line-height: normal;
  font-family: "Neuzeit Bold",sans-serif;
}

@media (min-width: 64em) {
  .catalog-detail .product .price-list {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .catalog-detail {
    margin: 0;
  }
}

.catalog-detail .catalog-detail-image {
  margin-top: 0;
  padding: 25px 25px 0 25px;
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .catalog-detail .catalog-detail-image {
    padding: 25px;
  }

  .catalog-detail .catalog-detail-image .image-single {
    height: 200px;
  }

  .catalog-detail .catalog-detail-image .image-single .item {
    height: 200px;
  }
}

.catalog-detail .product-specs {
  width: 100%;
}

.catalog-session {
  padding: 0;
  margin-top: 35px;
}

.catalog-detail-basket {
  margin: 0;
}

.catalog-detail-similar {
  margin-top: 35px;
}

.catalog-detail-similar .header {
  padding: 10px 1em;
}

.catalog-detail--stage .catalog-stage-navigator {
  display: none;
}

@media (max-width: 767px) {
  .detail-info-hint-box {
    margin-bottom: 50px;
  }
}

.detail-info-hint-box .product-detail-info-text {
  font-size: 14px;
  font-family: "Neuzeit Regular",sans-serif;
  color: #4b4b4b;
}

.detail-info-hint-box .info {
  min-width: 30px;
  max-height: 30px;
  margin-right: 15px;
  margin-left: 0;
}

.new-badge-detail {
  margin: 5px 20px;
  height: 25px;
  z-index: 1;
  float: right;
}

.preorder-box {
  display: flex;
  margin-top: 30px;
}

.preorder-info {
  width: 52px !important;
  margin: 15px 5px 15px;
}

.preorder-text {
  padding: 8px 3px 4px;
}

.catalog-list {
  background-color: #fff;
}

.pagination {
  padding: 25px;
}

.pagination:last-child .limits {
  display: none;
}

.pagination .limit ul,
.pagination .sort ul {
  width: 100%;
}

.pagination .limit ul li,
.pagination .sort ul li {
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 3px;
  font-size: 14px;
}

.pagination .limit ul li a,
.pagination .sort ul li a {
  display: block;
}

.pagination .limit ul li svg.white,
.pagination .sort ul li svg.white {
  display: none !important;
}

.pagination .limit ul li.active,
.pagination .sort ul li.active {
  background: #802538;
}

.pagination .limit ul li.active a,
.pagination .sort ul li.active a {
  color: #fff;
}

.pagination .limit ul li.active svg.white,
.pagination .sort ul li.active svg.white {
  display: inline-block !important;
}

.pagination .limit ul li.active svg.primary,
.pagination .sort ul li.active svg.primary {
  display: none !important;
}

.pagination .limit ul li:hover,
.pagination .sort ul li:hover {
  background: #802538;
}

.pagination .limit ul li:hover a,
.pagination .sort ul li:hover a {
  color: #fff;
}

.pagination .limit ul li:hover svg.white,
.pagination .sort ul li:hover svg.white {
  display: inline-block !important;
}

.pagination .limit ul li:hover svg.primary,
.pagination .sort ul li:hover svg.primary {
  display: none !important;
}

.pagination .limit .limit-select,
.pagination .limit .sort-select,
.pagination .sort .limit-select,
.pagination .sort .sort-select {
  border-color: #802538;
  height: 40px;
  padding: 10px 15px;
  border-radius: 10px;
  align-items: center;
  font-size: 14px;
  color: #802538;
}

@media (max-width: 767px) {
  .pagination .limit .limit-select,
  .pagination .limit .sort-select,
  .pagination .sort .limit-select,
  .pagination .sort .sort-select {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.pagination .limit .limit-select-content,
.pagination .limit .sort-select-content,
.pagination .sort .limit-select-content,
.pagination .sort .sort-select-content {
  border-color: #802538;
  top: 30px;
  border-top: none;
  border-radius: 0 0 10px 10px;
  width: 100%;
  padding: 10px 0 5px 0 !important;
}

@media (max-width: 767px) {
  .pagination .limit,
  .pagination .sort {
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }
}

.pagination .browser-container .browser {
  border-color: #802538;
  border-radius: 10px;
  height: 40px;
}

.pagination .browser-container .browser a,
.pagination .browser-container .browser span {
  border-color: #802538;
  font-size: 14px;
  padding: 10px;
  color: #802538;
  line-height: 18px;
}

@media (max-width: 767px) {
  .pagination .browser-container .browser {
    width: 100%;
    margin-bottom: 10px;
  }

  .pagination .browser-container .browser a,
  .pagination .browser-container .browser span {
    padding: 10px 7px;
  }

  .pagination .browser-container .browser .prev {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
  }

  .pagination .browser-container .browser .next {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
  }
}

.catalog-filter-attribute {
  margin: 0;
  background-color: #fff;
  padding: 2em 25px;
}

.catalog-filter-attribute .attribute-lists .attr-list {
  border: 1px solid #802538;
  border-radius: 0 0 10px 10px;
  top: -10px;
  position: relative;
  margin: 0;
  left: -1px;
  border-top: 0;
  padding: 15px 0 5px 0;
  font-size: 14px;
  background: #fff;
}

.catalog-filter-attribute .attribute-lists .attr-item {
  padding: 0 !important;
}

.catalog-filter-attribute .attribute-lists .attr-item .attr-name {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 3px;
}

.catalog-filter-attribute .attribute-lists .attr-item .attr-name:before {
  content: unset;
}

.catalog-filter-attribute .attribute-lists .attr-item .attr-name:hover {
  color: #fff;
  background: #802538;
}

.catalog-filter-attribute .attribute-lists .attr-item:checked+.attr-name {
  background: #802538;
  color: #fff;
}

.catalog-filter-attribute .attribute-lists .attr-item:checked+.attr-name:before {
  content: unset;
}

.catalog-filter-attribute fieldset {
  width: auto;
  padding: 0;
  margin-right: 5px;
}

.catalog-filter-attribute fieldset legend {
  background: #fff;
  color: #802538;
  font-size: 14px;
  padding: 7px 20px;
  border: 1px solid #802538;
  border-radius: 10px;
}

.catalog-filter-attribute fieldset legend svg {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.catalog-filter-attribute fieldset legend:after {
  content: unset;
}

.catalog-filter-attribute fieldset legend:hover {
  background: #fff;
}

@media (max-width: 767px) {
  .catalog-filter-attribute fieldset {
    width: 100%;
    margin-bottom: 5px;
  }
}

.checkout-standard {
  padding: 25px;
  background: #fff;
}

.accessories-text,
.basket-button {
  display: none;
}

.accessories-text {
  line-height: 36px;
  padding: 0 10px;
}

.aimeos-container .accessories-text {
  display: flex;
}

.aimeos-container .basket-button {
  display: block;
}

.aimeos-container .checkout-button {
  display: none;
}

.aimeos.checkout-confirm {
  background: #fff;
  padding: 25px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.aimeos.catalog-list .product .price-list {
  padding: 5px 0;
}

.aimeos.catalog-list .product .price-list:hover .price-text {
  margin-top: -27px;
}

.aimeos.catalog-list .product .price-list .price-actual .price-text {
  padding-top: 1px;
}

.aimeos.catalog-list .product .price-list .price-actual .price-text .value {
  vertical-align: text-top;
  font-weight: normal;
}

.aimeos.catalog-list .product .price-list .price-actual .price-text .currency {
  vertical-align: text-top;
}

.aimeos.catalog-list .product .text-list {
  min-height: 150px;
  align-items: initial;
}

.aimeos.catalog-list .product .text-list h2 {
  font-size: 19px;
  text-transform: inherit;
  font-weight: 700;
  height: auto;
  color: #3b3b3b;
  line-height: 23px;
  margin-bottom: 0;
}

.aimeos.catalog-list .product .offer {
  border-radius: 15px;
  overflow: hidden;
}

.catalog-list a .media-list,
.aimeos .suggest-item .item-image,
.basket-related-bought .media-list,
.catalog-detail-bought .media-list,
.catalog-detail-bundle .media-list,
.catalog-detail-suggest .media-list,
.catalog-session-seen .media-list,
.account-watch .media-item,
.account-favorite .media-item,
.basket-related-bought .media-item,
.catalog-detail-bought .media-item,
.catalog-detail-bundle .media-item,
.catalog-detail-suggest .media-item,
.catalog-session-seen .media-item,
.catalog-session-pinned .media-item,
.catalog-detail-image .image-single {
  background-image: url("../images/placeholder.png");
}

.aimeos-container .catalog-detail-suggest {
  display: none;
}

.aimeos.catalog-list.buy-box .product .price-list {
  background-color: #581a27;
  border: 1px solid #581a27;
}

.aimeos .buy-box .btn-primary {
  padding: 0;
  width: 10em;
  background-color: #581a27;
  border: 1px solid #581a27;
}

.buy-box .product {
  margin: 0;
}

.buy-box .product .media-item {
  background: #fff;
}

.ui-menu .ui-state-active,
.ui-menu .ui-menu-item {
  background-color: #fff !important;
}

.ui-menu .ui-state-active:hover,
.ui-menu .ui-menu-item:hover {
  background-color: silver !important;
}

.ui-menu .ui-state-active:focus,
.ui-menu .ui-menu-item:focus {
  background-color: silver !important;
}

.service-choose .btn-primary {
  color: #fff;
}

.scooter_notice {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  padding: 1rem;
  background: #ffeded;
  border: 1px solid #f00000;
  border-radius: 10px;
  margin: 0 0 2rem 0;
  flex: none;
  order: 3;
  flex-grow: 0;
}

.scooter_notice span {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #8a0000;
}

.scooter_notice svg {
  width: 32px;
}

.scooter_notice .dealer-search-link {
  text-decoration: underline !important;
  color: #8a0000 !important;
}

.detail-back {
  display: grid;
  justify-items: start;
}

.detail-back svg {
  stroke: #802538;
  margin-right: 1rem;
}

.detail-back a {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.catalog-stage-subnavigation {
  margin-bottom: 30px;
}

.catalog-stage-subnavigation .catalog-subnavigation {
  overflow: hidden;
  border: 0 none;
  border-radius: 20px;
  overflow: hidden;
}

.catalog-stage-subnavigation .catalog-subnavigation a {
  border: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px;
}

.catalog-stage-subnavigation .catalog-subnavigation.selected {
  background-color: #802538;
}

.catalog-stage-subnavigation .catalog-subnavigation.selected span {
  color: #fff;
}

.catalog-stage-subnavigation .catalog-subnavigation .sub-navigation-content {
  padding: 20px;
}

.catalog-stage-subnavigation .catalog-subnavigation .sub-navigation-content .sub-navigation-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.catalog-stage-subnavigation .catalog-subnavigation .sub-navigation-content .sub-navigation-image-container img {
  width: 150px;
}

.catalog-stage-subnavigation .catalog-subnavigation .sub-navigation-content .sub-navigation-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.catalog-stage-subnavigation .catalog-subnavigation .sub-navigation-content .sub-navigation-text-container span {
  text-align: center;
}

.calculator-saving-layer {
  background-color: #ececed;
  padding: 20px 10px 10px 10px;
  font-size: 14px;
  margin-bottom: 25px;
}

.calculator-saving-layer .saving-box {
  padding: 5px 0;
}

.calculator-saving-layer .button-layer {
  margin-bottom: 0;
  margin-top: 10px;
}

@media (min-width: 64em) {
  .calculator-saving-layer {
    padding: 8px;
    font-size: 16px;
    margin-bottom: 0;
  }

  .calculator-saving-layer .button-layer {
    margin-bottom: 0;
    margin-top: 0;
  }
}

#calculator_details .info-small {
  max-width: 30px;
  max-height: 30px;
}

.static-content {
  padding: 25px;
  background: #fff;
}

.service.contact {
  background: #fff;
  padding: 25px;
  margin-top: 0;
  margin-bottom: 35px;
}

.calculator.calculator-page {
  padding: 25px;
  background: #fff;
}

.calculator .buttons {
  margin-top: 30px;
}

@media (min-width: 64em) {
  .calculator .buttons {
    margin-top: 0;
  }
}

.calculator .calculate {
  font-size: 18px;
  line-height: 19px;
  min-height: 60px;
  padding: 10px 80px 5px 80px;
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  font-family: "Neuzeit Regular",sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 64em) {
  .calculator .calculate {
    width: auto;
  }
}

.calculator .image svg {
  width: 50%;
  margin: 0 auto;
}

@media (min-width: 64em) {
  .calculator .image svg {
    width: 100%;
  }
}

.calculator-tiles {
  margin-bottom: 10px;
}

.calculator-tiles .tile {
  background: #fff;
  padding: 20px;
  border: none;
  height: auto;
}

.calculator-tiles .tile .tiles-header h3 {
  color: #000;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.calculator-tiles .tile .tiles-text {
  display: none;
  min-height: auto;
}

.calculator-tiles .tile .tiles-text p {
  color: #000;
}

.calculator-tiles .tile .tiles-button {
  height: auto;
  padding: 5px 45px;
  line-height: 20px;
  font-size: 16px;
  background: #fff;
  border: 1px solid #802538;
  color: #802538;
}

.calculator-tiles .tile .tiles-button .less {
  display: none;
}

.all-around-protection .protection-head {
  padding: 15px;
  background-color: #fff;
  margin-bottom: 20px;
}

.all-around-protection .protection-head .icon {
  width: 100%;
  height: 320px;
}

.all-around-protection .protection-head .headlines {
  padding: 0;
  display: flex;
  align-items: flex-end;
}

.all-around-protection .protection-head .headlines a {
  text-decoration: underline;
  color: #000;
}

.all-around-protection .protection-head .headlines span {
  font-size: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

@media (min-width: 64em) {
  .all-around-protection .protection-head .headlines span {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .all-around-protection .protection-head .headlines {
    margin-bottom: 15px;
  }
}

.all-around-protection .calculation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
}

.all-around-protection .calculation-container img {
  width: 100px;
}

.all-around-protection .calculation-container .calculator-content span {
  font-size: 30px;
  font-weight: 700;
}

.all-around-protection .calculation-container .calculator-content--button .calculator-btn {
  background-color: #802538;
  padding: 10px 45px;
  text-align: center;
  border-radius: 10px;
}

.all-around-protection .calculation-container .calculator-content--button .calculator-btn span {
  color: #fff;
  font-weight: 700;
}

.all-around-protection .eleasa-security-content {
  background-color: #fff;
  padding: 40px;
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.all-around-protection .eleasa-security-content .eleasa-headline img {
  width: 60%;
}

.all-around-protection .eleasa-security-content .eleasa-img img {
  height: 100%;
  width: 100%;
}

.all-around-protection .eleasa-info-box {
  background-color: #fff;
  margin: 30px 0 10px 0;
  line-height: 14px;
  padding: 20px 20px 35px 30px;
}

.all-around-protection .eleasa-info-box a {
  color: #802538;
}

.all-around-protection .eleasa-info-box span {
  font-size: 12px;
}

.all-around-protection .eleasa-security-content .content-block {
  margin: 10px 0;
  line-height: 18px;
}

.all-around-protection .icon.eleasagadgets {
  width: 100%;
  height: 100%;
}

.all-around-protection .icon.taschenrechner {
  width: 60px;
}

.all-around-protection .content-tile-image-container svg {
  width: 150px;
  height: 250px;
}

.all-around-protection .content-tile-image-container .icon.garantieverlaengerung {
  width: 240px;
}

.all-around-protection .content-tile-image-container .icon.vandalismuspc {
  width: 200px;
}

.faq .faq-header .topic {
  background: #fff;
  border-radius: 5px;
  padding: 5px 25px;
  margin-right: 5px;
  cursor: pointer;
  min-width: 140px;
  text-align: center;
  transition: ease all .3s;
  width: 100%;
  margin-bottom: 15px;
}

.faq .faq-header .topic.active,
.faq .faq-header .topic:hover {
  color: #fff;
  background: #802538;
}

@media (min-width: 64em) {
  .faq .faq-header .topic {
    width: auto;
  }
}

.faq .faq-body {
  border-top: 1px solid #dbdbdb;
  padding-top: 15px;
  margin-top: 0;
}

.faq .faq-body .faq-item {
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
}

@media (min-width: 64em) {
  .faq .faq-body {
    border: none;
    padding-top: 0;
  }
}

.faq-content {
  position: relative;
  margin-bottom: 50px;
  padding-top: 0;
}

@media (min-width: 64em) {
  .faq-content {
    padding-top: 100px;
    margin-bottom: 0;
  }
}

.faq-content .eleasageht {
  max-height: 100%;
  height: 500px;
}

@media (max-width: 767px) {
  .faq-content .eleasageht {
    position: relative;
    margin: 0 auto;
    max-width: 35%;
    display: block;
    height: 250px;
  }
}

@media (min-width: 48em) {
  .faq-content .eleasageht {
    max-width: 35%;
  }
}

@media (min-width: 64em) {
  .faq-content .eleasageht {
    max-width: 65%;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .faq-content .eleasageht {
    max-width: 35%;
  }
}

.faq-content .eleasa-speech-bubble {
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  width: 55%;
  position: absolute;
  font-size: 18px;
}

@media (max-width: 767px) {
  .faq-content .eleasa-speech-bubble {
    position: relative;
    font-size: 12px;
    display: block;
    margin: 0 auto;
  }
}

@media (min-width: 48em) {
  .faq-content .eleasa-speech-bubble {
    top: 10%;
    right: 2%;
    margin: -28px 30px;
  }
}

@media (min-width: 64em) {
  .faq-content .eleasa-speech-bubble {
    top: 4%;
    right: -4%;
  }
}

@media (min-width: 1024px)and (max-width: 1199px) {
  .faq-content .eleasa-speech-bubble {
    right: -2%;
  }
}

.faq-content .eleasa-speech-bubble.left:after {
  top: 119px;
  left: -28px;
  border-left: 15px solid rgba(0,0,0,0);
  border-right: 15px solid #ececed;
  border-top: 15px solid rgba(0,0,0,0);
  border-bottom: 15px solid #ececed;
}

@media (max-width: 767px) {
  .faq-content .eleasa-speech-bubble.left:after {
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid rgba(0,0,0,0);
    border-bottom: 20px solid #ececed;
    left: 50%;
    top: -39px;
  }
}

.faq-content .eleasa-speech-bubble.left:before {
  top: 120px;
  left: -31px;
  border-left: 15px solid rgba(0,0,0,0);
  border-right: 15px solid #000;
  border-top: 15px solid rgba(0,0,0,0);
  border-bottom: 15px solid #000;
}

@media (max-width: 767px) {
  .faq-content .eleasa-speech-bubble.left:before {
    left: 50%;
    top: -40px;
    border-left: 20px solid rgba(0,0,0,0);
    border-right: 20px solid rgba(0,0,0,0);
    border-top: 20px solid rgba(0,0,0,0);
    border-bottom: 20px solid #000;
  }
}

.faq-content .content_two {
  padding-left: 21px;
}

@media (max-width: 767px) {
  .faq-content .content_two {
    padding: 0;
  }
}

.faq-content .content_three {
  padding-left: 0;
}

@media (max-width: 767px) {
  .faq-content .content_three {
    padding: 0;
  }
}

.faq-content .content_six {
  padding-left: 20px;
}

@media (max-width: 767px) {
  .faq-content .content_six {
    padding: 0;
  }
}

.faq-content .content_seven {
  padding-left: 70px;
}

@media (max-width: 767px) {
  .faq-content .content_seven {
    padding: 0;
  }
}

.faq-content span {
  display: block;
  font-family: "Bradford Medium",sans-serif;
}

.portal--safety-first {
  background-color: #fff;
}

.portal--safety-first .icon {
  width: 100%;
}

.portal--safety-first .mechaniker {
  height: 100%;
  width: 70%;
}

@media (max-width: 767px) {
  .portal--safety-first .safety-box {
    margin: 20px 0;
  }
}

.portal--safety-first .safety-box .safety-box-inner {
  border: 1px solid #000;
  border-radius: 50px;
  margin-bottom: 20px;
  padding: 20px;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  font-family: "Neuzeit Regular",sans-serif;
  height: 100%;
}

.portal--safety-first .portal--safety-first-headline {
  font-size: 32px;
  padding-bottom: 25px;
  font-family: "Bradford Medium",sans-serif;
  margin-top: 40px;
}

@media (min-width: 48em) {
  .portal--safety-first .portal--safety-first-headline {
    margin-bottom: 50px;
  }
}

.portal--safety-first .portal--safety-first-headline-second {
  font-size: 23px;
  font-family: "Neuzeit Regular",sans-serif;
}

@media (min-width: 48em) {
  .portal--safety-first .portal--safety-first-headline-second {
    margin-bottom: 80px;
  }
}

.portal--safety-first .portal--headline {
  text-align: center !important;
  width: 100%;
}

.portal--safety-first .portal--headline h6 {
  font-size: 18px;
  font-family: "Neuzeit Bold",sans-serif;
}

@media (min-width: 64em) {
  .portal--safety-first .portal--headline h6 {
    font-size: 25px;
  }
}

.portal--safety-first .portal--headline p {
  font-size: 14px;
  text-align: center !important;
}

@media (min-width: 64em) {
  .portal--safety-first .portal--headline p {
    font-size: 16px;
  }
}

.portal--safety-first .portal--body {
  text-align: center;
}

.portal--safety-first .portal--body .icon {
  max-width: 120px;
  width: 120px;
  margin-bottom: 10px;
}

.portal--safety-first .portal--safety-first-hint {
  margin: 30px 80px 0 80px;
}

.portal--safety-first .portal--safety-first-hint .portal--safety-first-hintbox {
  background: #eee;
  border: 1px solid #000;
  padding: 5px;
  font-size: 25px;
  border-radius: 20px;
  text-align: center;
}

section.service-packages-processing-container {
  padding: 2rem 0;
}

section.service-packages-processing-container .icon {
  width: 100%;
}

.service-packages-processing {
  display: grid;
  gap: 20px;
}

@media (max-width: 767px) {
  .service-packages-processing {
    display: flex;
    flex-direction: column;
  }
}

.service-packages-processing>div {
  border: 1px solid #000;
  border-radius: 50px;
  margin-bottom: 20px;
  padding: 20px;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  font-family: "Neuzeit Regular",sans-serif;
  height: 100%;
}

.max-icon {
  max-width: 310px;
}

.number-circle {
  border-radius: 50%;
  text-align: center;
  height: 45px;
  background-color: #000;
  width: 45px;
  margin: 0px;
}

.number-circle .number {
  text-align: center;
  color: #fff;
  display: inline-block;
  font-size: 30px;
  font-family: "Bradford Medium",sans-serif;
}

.shop-intro .icon {
  width: 100%;
}

.intro--advantages-overlay {
  background-color: #802538;
  padding: 55px 40px;
}

.intro--advantages-overlay .icon {
  width: 100%;
}

.intro--advantages-overlay .intro--advantages {
  background-color: #802538;
}

.intro--advantages-overlay .intro--advantages .intro--headline {
  color: #fff;
  font-size: 23px;
  font-family: "Bradford Medium",sans-serif;
  padding-bottom: 30px;
}

@media (min-width: 48em) {
  .intro--advantages-overlay .intro--advantages .intro--headline {
    padding-bottom: 50px;
    font-size: 39px;
  }
}

.intro--advantages-overlay .intro--advantages .intro--advantages-boxes {
  justify-content: center;
}

.intro--advantages-overlay .intro--advantages .intro--advantages-boxes .intro--advantages-box {
  margin-top: 15px;
}

.intro--advantages-overlay .intro--advantages .intro--advantages-boxes .icon {
  width: 100%;
  stroke: #fff;
  max-height: 130px;
  margin: 0 auto;
  display: block;
  stroke-width: .8;
}

.intro--advantages-overlay .intro--advantages .intro--advantages-boxes .intro--advantage-text {
  display: block;
  font-family: "Neuzeit Bold",sans-serif;
  text-align: center;
  font-size: 20px;
  padding-top: 30px;
  color: #fff;
}

@media (max-width: 767px) {
  .intro--advantages-overlay .intro--advantages .intro--advantages-boxes .intro--advantage-text {
    max-width: unset;
    width: auto;
  }
}

#intro--banner {
  overflow: hidden;
}

@media (max-width: 767px) {
  #intro--banner {
    max-height: 520px;
    height: 520px;
  }
}

#intro--banner .eleasaauffahrrad {
  width: 100%;
  height: 100%;
}

#intro--banner .intro-text {
  display: block;
  font-family: "Bradford Medium",sans-serif;
  font-size: 39px;
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  #intro--banner .intro-text {
    margin-top: 20px;
    font-size: 30px;
  }
}

#intro--banner .intro-image {
  display: block;
  width: 100%;
}

@media (max-width: 767px) {
  #intro--banner .intro-image {
    display: none;
  }
}

#intro--banner .intro-grid {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 767px) {
  #intro--banner .intro-grid {
    display: block;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

#intro--banner .intro-anchor-links {
  font-size: 14px;
}

@media (max-width: 767px) {
  #intro--banner .intro-anchor-links {
    font-size: 25px !important;
  }
}

#intro--banner .intro--textbox {
  padding: 40px 20px;
}

@media (max-width: 767px) {
  #intro--banner .intro--textbox {
    padding: 20px;
  }
}

#intro--banner .intro--textbox .anchor-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 48em) {
  #intro--banner .intro--textbox .anchor-block {
    align-items: flex-start;
  }
}

#intro--banner .intro--textbox .anchor-block .btn-anchor {
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 20px;
  line-height: 1;
  transition: background-color .2s ease;
  height: 70px;
}

@media (min-width: 48em) {
  #intro--banner .intro--textbox .anchor-block .btn-anchor {
    min-width: 240px;
    height: 40px;
  }
}

#intro--banner .intro--textbox .anchor-block .btn-anchor:hover {
  cursor: pointer;
  background-color: #802538;
}

#intro--banner .intro--textbox .anchor-block .btn-anchor:hover a {
  color: #fff;
}

#intro--banner .intro--textbox .anchor-block .btn-anchor:hover a .pfeil-lang {
  stroke: #fff;
}

#intro--banner .intro--textbox .anchor-block .btn-anchor:hover a .pfeil-lang .st0 {
  stroke: #fff;
}

#intro--banner .intro--textbox .anchor-block .btn-anchor a {
  color: #802538;
  transition: color .2s ease;
  font-size: 14px;
  font-family: "Bradford Medium",sans-serif;
}

@media (min-width: 48em) {
  #intro--banner .intro--textbox .anchor-block .btn-anchor a {
    font-size: 20px;
  }
}

#intro--banner .intro--textbox .anchor-block .btn-anchor a:hover {
  text-decoration: none;
}

#intro--banner .intro--textbox .anchor-block .btn-anchor a .pfeil-lang {
  width: 14px;
  stroke: #802538;
  padding: 0;
  margin-right: 10px;
}

@media (min-width: 48em) {
  #intro--banner .intro--textbox .anchor-block .btn-anchor a .pfeil-lang {
    width: 25px;
    margin-right: 20px;
  }
}

#intro--banner .intro--textbox .anchor-block .btn-anchor a .pfeil-lang .st0 {
  stroke: #802538;
}

.col-span-2 {
  grid-column: span 2/span 2;
}

.col-span-3 {
  grid-column: span 3/span 3;
}

.col-span-4 {
  grid-column: span 4/span 4;
}

.col-span-5 {
  grid-column: span 5/span 5;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.bg-white {
  background: #fff;
}

.text-xs {
  font-size: 14px;
}

.text-sm {
  font-size: 16px;
}

.text-base {
  font-size: 24px;
}

.text-lg {
  font-size: 26px;
}

.section-calculator {
  background: #edf2f7;
  padding: 10px 0;
}

.calculation {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding-bottom: .5rem;
}

.calculation>div {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.calculation .headline {
  padding-bottom: 2rem;
}

.headline {
  font-family: "Bradford Medium",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: .03em;
  display: block;
}

.box-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
  padding: 0 0 2rem;
}

@media (max-width: 767px) {
  .box-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.box {
  background-color: #802538;
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  font-size: 19px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.box .box-content {
  display: grid;
  gap: 10px;
  padding-bottom: 2rem;
  font-weight: 400;
  font-size: 19px;
  line-height: 140%;
}

.box .box-content .headline {
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
}

.box a {
  border: 1px solid #fff;
  border-radius: 10px;
  display: inline-block;
  color: #802538;
  background-color: #fff;
  padding: 2px 10px;
  align-self: flex-end;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
}

.intro--how-to-container {
  padding: 4rem 0;
}

.intro--how-to-body {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  justify-content: center;
  padding: 2rem 0;
}

@media (max-width: 767px) {
  .intro--how-to-body {
    display: flex;
    flex-direction: column;
  }
}

.intro--how-to-body>div {
  padding: 20px;
  border-radius: 40px;
  border: 1px solid #000;
  font-size: 19px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.intro--how-to-body>div .svg {
  display: grid;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
}

.intro--how-to-body>div .svg svg {
  height: 160px;
}

.intro--how-to-body>div a {
  background: #802538;
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  padding: 0 15px;
}

.dealer-search--info-box {
  margin-top: 65px;
  font-family: "Neuzeit Regular",sans-serif;
  font-size: 20px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}

.dealer-search--info-box a {
  color: #000;
  text-decoration: underline;
}

@media (min-width: 48em) {
  .dealer-search--info-box {
    font-size: 23px;
  }
}

.dealer-search--info-box span {
  display: inline-block;
  padding-top: 10px;
}

.dealer-search--info-box .portal-info {
  margin-right: 17px;
  display: inline-block;
}

@media (min-width: 48em) {
  .dealer-search--info-box .portal-info {
    width: 41px;
    height: 41px;
    min-width: 41px;
  }
}

.dealer-search--info-box.desired-bike {
  background-color: #802538;
  border-radius: 30px;
  padding: .6rem 1rem;
  font-size: 22px;
  color: #fff;
  margin: 5rem 0;
}

.dealer-search--info-box.desired-bike a {
  color: #fff;
  white-space: nowrap;
}

.dealer-search--info-box.desired-bike .portal-info {
  width: 62px;
  height: 60px;
  min-width: 60px;
}

.dealer-search--info-box {
  margin-top: 65px;
  font-family: "Neuzeit Regular",sans-serif;
  font-size: 20px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
}

.dealer-search--info-box a {
  color: #000;
  text-decoration: underline;
}

@media (min-width: 48em) {
  .dealer-search--info-box {
    font-size: 23px;
  }
}

.dealer-search--info-box span {
  display: inline-block;
  padding-top: 10px;
}

.dealer-search--info-box .dealer-search-info {
  margin-right: 17px;
  display: inline-block;
}

@media (min-width: 48em) {
  .dealer-search--info-box .dealer-search-info {
    width: 41px;
    height: 41px;
    min-width: 41px;
  }
}

.dealer-search--info-box.desired-bike {
  background-color: #802538;
  border-radius: 30px;
  padding: .6rem 1rem;
  font-size: 22px;
  color: #fff;
  margin: 5rem 0;
}

.dealer-search--info-box.desired-bike a {
  color: #fff;
  white-space: nowrap;
}

.dealer-search--info-box.desired-bike .dealer-search-info {
  width: 62px;
  height: 60px;
  min-width: 60px;
}

.dealer-search--how-to-container {
  width: 100%;
  padding-bottom: 80px;
}

.dealer-search--how-to-container .dealer-search--how-to-headline {
  font-family: "Bradford Medium",sans-serif;
  font-size: 39px;
  padding-bottom: 40px;
  padding-top: 80px;
}

.dealer-search--how-to-container .dealer-search--how-to-body {
  font-size: 13px;
  margin: 0 auto;
}

.dealer-search--how-to-container .dealer-search--how-to-body .customer {
  font-size: 11px;
  text-align: center;
  margin-right: 0;
  display: inline-block;
}

.dealer-search--how-to-container .dealer-search--how-to-body .customer.customer-budget {
  margin-right: 0;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 390px;
}

@media (max-width: 767px) {
  .dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item {
    padding-bottom: 35px;
    margin-bottom: 40px;
  }
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .how-to-item-inner {
  border: 1px solid #000;
  border-radius: 50px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .head-text {
  text-align: left;
  font-size: 14px;
  font-family: "Neuzeit Regular",sans-serif;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .btn-store {
  background-color: #802538;
  margin: 30px auto 8px auto;
  border-radius: 20px;
  display: inline-block;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .btn-store a {
  padding: 7px 50px;
  font-size: 20px;
  font-family: "Bradford Medium",sans-serif;
  color: #fff;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .btn-store a:hover {
  text-decoration: none;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .additional-info {
  margin: 15px -15px;
  font-size: 23px;
  font-family: "Neuzeit Bold",sans-serif;
  padding: 15px 0;
  background-color: #fff;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .additional-info .no-budget {
  font-size: 16px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .icon {
  stroke: #000;
  display: block;
  margin: auto auto 0 auto;
  width: 100%;
  height: 180px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .icon.rennrad {
  width: 200px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .icon.vertrag {
  width: 180px;
  margin: auto auto 0 auto;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .icon.smartphonechat {
  width: 170px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .icon.rechnung {
  width: 150px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .dealer-search--how-to-text {
  text-align: center;
  font-family: "Source Sans Pro",sans-serif;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .dealer-search--how-to-text .text-block {
  min-height: 85px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .dealer-search--how-to-text .number-circle {
  border-radius: 50%;
  background-color: #000;
  width: 45px;
  margin: 0 5px;
}

.dealer-search--how-to-container .dealer-search--how-to-body .dealer-search--how-to-item .dealer-search--how-to-text .number-circle .number {
  text-align: center;
  color: #fff;
  display: inline-block;
  font-size: 30px;
  font-family: "Bradford Medium",sans-serif;
}

.tile {
  background-color: #802538;
  color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tile .tiles-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 0px)and (max-width: 991px) {
  .tile .tiles-button-wrapper {
    flex-direction: column;
  }
}

.tile .tiles-button {
  border-radius: 10px;
  background-color: #802538;
  border: 1px solid #fff;
  padding: 5px 40px;
  height: 30px;
  margin-bottom: 5px;
  display: inline-block;
}

.tile .img-holder {
  text-align: right;
}

@media (min-width: 0px)and (max-width: 991px) {
  .tile .img-holder {
    text-align: center;
    padding: 1rem;
  }
}

.tile svg {
  stroke: #fff;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.two-tiles {
  padding-top: 15px;
  width: 100%;
}

.content-tile {
  margin-top: 15px;
}

.content-tile .content-tile-container {
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-tile .content-tile-container .content-tile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-tile .content-tile-container .content-tile-image-container img {
  width: 190px;
}

.content-tile .content-tile-container .content-tile-text-container {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}

.a-left,
.a-right {
  background-color: rgba(0,0,0,0);
}

.a-left:after {
  content: "";
}

.a-right:after {
  content: "";
}

.brand-slider {
  margin-top: 30px;
  background: #fff;
  border-radius: 20px;
}

.brand-slider .headline-wrapper {
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.brand-slider .headline-wrapper h2.headline {
  color: #000;
  display: inline-block;
  text-transform: none !important;
  position: relative;
  transform: translate(0);
  top: 0;
  left: 0;
  padding: 0;
}

.brand-slider .slick-track {
  padding-bottom: 80px;
}

.slider {
  padding-top: 15px;
}

#product-slider {
  padding-bottom: 30px;
}

#product-slider .a-left,
#product-slider .a-right {
  top: 25%;
  transform: translateY(-25%);
}

#product-slider .offer {
  display: none;
}

.top-products {
  width: 100%;
  margin-top: 30px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.top-products .headline-wrapper {
  display: flex;
  align-items: center;
  padding: 19px 14px;
}

.top-products .headline-wrapper h2.headline {
  color: #000;
  display: inline-block;
  text-transform: none !important;
  position: relative;
  transform: translate(0);
  top: 0;
  left: 0;
  padding: 0;
}

.top-products .slick-slide {
  margin: 0 15px;
}

.top-products .slick-slide a {
  border-radius: 20px;
  overflow: hidden;
}

.top-products .slick-slide .product {
  border-bottom: 0;
  text-align: center;
}

.top-products .slick-slide .product .price-list {
  padding: 15px 0;
  background-color: #802538;
  width: 100%;
  color: #fff;
  font-size: 18px;
  height: 33px;
  text-align: center;
}

.top-products .slick-slide .product .price-list:hover .price-text {
  margin-top: -26px;
}

.top-products .slick-slide .product .price-list .price-actual {
  height: 26px;
  overflow: hidden;
}

.top-products .slick-slide .product .price-list .price-actual .price-text {
  transition: all .3s ease;
  padding-top: 2px;
}

.top-products .slick-slide .product .price-list .price-actual .price-text.isloading {
  display: none;
}

.top-products .slick-slide .product .price-list .price-actual .calc-icon {
  display: none;
}

.top-products .slick-slide .product .price-list .price-actual .ordernow__button-text.isloading,
.top-products .slick-slide .product .price-list .price-actual .cost.isloading {
  display: none;
}

.top-products .slick-slide .product .text-list {
  margin: 2em auto 1em auto;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-products .slick-slide .product .text-list h2 {
  font-size: 19px;
  text-transform: inherit;
  height: auto;
  color: #3b3b3b;
  line-height: 23px;
  margin-bottom: 0;
}

.top-products .slick-slide .product .text-list .text-item {
  font-size: 18px;
  height: auto;
}

.top-products .slick-slide .product .offer {
  margin-top: 15px;
}

.slick-dots {
  list-style: none;
  text-align: center;
  margin: 15px 0;
  padding: 0;
}

.slick-dots li {
  display: inline-block;
  margin-right: 5px;
}

.slick-dots li.slick-active button {
  content: "";
  border-radius: 50%;
  background-color: #000;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 0;
  color: #000;
  border: 1px solid #000;
  font-size: 0;
}

.slick-dots li button {
  content: "";
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #000;
  display: inline-block;
  color: #fff;
  font-size: 0;
}

#homepage-slider-mobile,
#homepage-slider {
  border-radius: 20px;
  overflow: hidden;
}

#homepage-slider-mobile .slick-slide img,
#homepage-slider .slick-slide img {
  border-radius: 20px;
  overflow: hidden;
}

.catalog-detail .catalog-detail-image .bu-image-single,
.catalog-detail .catalog-detail-image .image-single {
  max-width: 100%;
  height: 100%;
}

.catalog-detail .catalog-detail-image .bu-image-single #details-image-slider,
.catalog-detail .catalog-detail-image .image-single #details-image-slider {
  padding-top: 50px;
  padding-bottom: 15px;
}

.catalog-detail .catalog-detail-image .bu-image-single #details-image-slider .item,
.catalog-detail .catalog-detail-image .image-single #details-image-slider .item {
  max-width: 100%;
}

.catalog-detail #similar-products-slider {
  padding: 20px;
}

.catalog-detail #similar-products-slider .product .price-list {
  padding: 5px 0;
}

.catalog-detail #similar-products-slider .product .price-list .price-text {
  padding-top: 2px;
  text-align: center;
}

.catalog-detail #similar-products-slider .product .price-list:hover .price-text {
  margin-top: -25px;
}

.catalog-detail #similar-products-slider .product .price-list .ordernow__button-text {
  text-align: center;
}

.catalog-detail #similar-products-slider .product .text-list {
  max-width: 240px;
}

.catalog-detail #similar-products-slider .product .offer {
  margin-top: 15px;
  border-radius: 15px;
  overflow: hidden;
}

.eleasa-speaker {
  padding-bottom: 30px;
}

.eleasa-speaker .similar-products-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eleasa-speaker .similar-products-text .header {
  text-transform: unset;
  border: 0 none;
  font-weight: bold;
  font-size: 25px;
}

.eleasa-speaker .eleasakopf {
  width: 200px;
  height: 200px;
  margin-left: 60px;
  margin-bottom: -24px;
}

@media (max-width: 767px) {
  .eleasa-speaker .eleasakopf {
    display: block;
    margin: 0 auto;
  }
}

.info-wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 30px;
  padding: 20px;
}

.info-wrapper .eleasataschenrechner {
  width: 250px;
}

@media (max-width: 767px) {
  .info-wrapper .eleasataschenrechner {
    width: 50%;
    margin: 0 auto;
    display: block;
  }
}

.info-wrapper .info-box-text {
  font-size: 30px;
  width: 100%;
}

@media (max-width: 767px) {
  .info-wrapper .info-box-text {
    font-size: 15px;
    margin-top: 25px;
    text-align: center;
  }
}

.info-wrapper .info-box-text .icon {
  width: 110px;
  float: right;
}

@media (max-width: 767px) {
  .info-wrapper .info-box-text .icon {
    width: 75px;
  }
}

.info-wrapper .info-box-text span {
  display: block;
  font-family: "Bradford Medium",sans-serif;
}

.info-wrapper .info-box-text .info-box-text-second {
  padding-left: 30px;
}

.info-wrapper .info-box-text .info-box-text-third {
  padding-left: 60px;
}

.info-wrapper .info-box-text .info-box-text-fourth {
  padding-left: 30px;
}

.info-wrapper .info-box-text .info-box-text-fifth {
  padding-left: 114px;
}

.contact-box-wrapper {
  padding: 20px 50px;
  margin-top: 30px;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
}

.contact-box-wrapper .kundenservice {
  width: 175px;
  height: 130px;
  fill: none;
  stroke: #000;
}

@media (max-width: 767px) {
  .contact-box-wrapper .kundenservice {
    width: 120px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.contact-box-wrapper .contact-box--text {
  font-size: 30px;
}

@media (max-width: 767px) {
  .contact-box-wrapper .contact-box--text {
    font-size: 16px;
  }
}

.contact-box-wrapper .contact-box--text span {
  display: block;
  font-family: "Bradford Medium",sans-serif;
}

.contact-box-wrapper .contact-box--text .contact-text-second {
  padding-left: 15%;
}

.contact-box-wrapper .contact-box--text .contact-text-third {
  padding-left: 30%;
}

@media (max-width: 767px) {
  .info-text {
    padding: 0 !important;
  }
}

.order-announcement .mppdate {
  background: #050505;
}

.stroke-default {
  stroke: #fff !important;
}

.bicicli-iframe {
  width: 100%;
  max-width: 1450px;
  padding-left: 33px;
  height: 880px;
  border: #fff;
}

.bicicli-iframe-attributes {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 80px;
  border: #fff;
  background: #fff;
}

@media (max-width: 1300px) {
  .bicicli-iframe {
    padding: 0 20px 0 20px;
  }
}

@media (max-width: 1032px) {
  .bicicli-iframe {
    height: 1360px;
  }
}

@media (max-width: 679px) {
  .bicicli-iframe {
    height: 1280px;
  }
}

@media (max-width: 300px) {
  .bicicli-iframe {
    height: 1350px;
  }
}

.linktext {
  font-family: "Bradford Medium",sans-serif !important;
  color: #3b3b3b !important;
}

.linktext a:link,
.linktext a:visited,
.linktext a:hover,
.linktext a:active {
  color: #3b3b3b !important;
  text-decoration: underline !important;
}

.stroke-default {
  stroke: #fff !important;
}

.bicicli-iframe {
  width: 100%;
  max-width: 1450px;
  padding-left: 33px;
  height: 880px;
  border: #fff;
}

.bicicli-iframe-attributes {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 80px;
  border: #fff;
  background: #fff;
}

@media (max-width: 1300px) {
  .bicicli-iframe {
    padding: 0 20px 0 20px;
  }
}

@media (max-width: 1032px) {
  .bicicli-iframe {
    height: 1360px;
  }
}

@media (max-width: 679px) {
  .bicicli-iframe {
    height: 1280px;
  }
}

@media (max-width: 300px) {
  .bicicli-iframe {
    height: 1350px;
  }
}

.linktext {
  font-family: "Bradford Medium",sans-serif !important;
  color: #3b3b3b !important;
}

.linktext a:link,
.linktext a:visited,
.linktext a:hover,
.linktext a:active {
  color: #3b3b3b !important;
  text-decoration: underline !important;
}

